<template>
  <Navbar/>
    <SideBar/>

    <div class="main-content">
        <div class="progress-spinner" v-if="spinnerLoadingStatus">
            <ProgressSpinner></ProgressSpinner>
        </div>
        <div class="flex justify-content-between flex-wrap mt-2">
            <div class="flex align-items-center justify-content-center ml-4">
                <label class=" ml-2 text-xl font-medium">CMC Bills</label>
            </div>
            <div class="flex align-items-center justify-content-center mr-3">
                <Button label="Go back"  icon="pi pi-arrow-left" severity="secondary" text raised @click="$router.back()"/>
            </div>
        </div>


        <div class="grid">
            <div class="sm:col-12 md:col-12 lg:col-12">
                <div class="flex ">
                    <div class="flex-initial flex justify-content-center w-full ml-3 "> 
                        <div class="surface-card shadow-2 p-3 border-round-xl  w-full mt-4 mr-3 ml-3">
                            <DataTable :value="filteredCmcBills" ref="cmcBillsRef" size="small" :loading="tableLoading" v-model:filters="filters" tableStyle="min-width: 50rem" v-model:expandedRows="expandedRows" dataKey="id" @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" paginator :rows="50" :rowsPerPageOptions="[10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" scrollable scrollHeight="500px">

                                <template #empty>
                                    <div class="flex ">
                                        <div class="flex-initial flex justify-content-center w-full"> 
                                            <label class="font-medium" >No data</label>
                                        </div>
                                    </div>
                                </template>

                                <template #header >
                                    <div class="flex justify-content-between flex-wrap">
                                        <div class="flex align-items-center justify-content-center text-xl">
                                            <router-link to="/new-servicing-bill" rel="noopener">
                                                <Button raised label="New bill" icon="pi pi-plus" size="small" class="ml-2 px-4" />
                                            </router-link>
                                        </div>
                                        <div class="flex align-items-center justify-content-center">
                                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                                        </div>
                                    </div>
                                </template>

                                <Column expander style="width: 5rem"/>
                                <Column field="billNo" header="Bill No.">
                                    <template #body="slotProps">
                                        <router-link :to="{
                                            name: 'cmc-bill-doc',
                                            params:{
                                                id:slotProps.data.id,
                                            }
                                        }" target="_blank" rel="noopener">
                                            {{slotProps.data.billNo}}
                                        </router-link>
                                    </template>
                                </Column>
                                <Column field="billDate" header="Date"></Column>
                                <Column field="item" header="Service"></Column>
                                <Column field="orderNo" header="Order No."></Column>
                                <Column field="orderDate" header="Order Date"></Column>
                                <Column field="taxable" header="Taxable">
                                    <template #body="slotProps" >
                                        {{formatCurrency(slotProps.data.taxable)}}
                                    </template>
                                </Column>
                                <Column field="gst" header="GST">
                                    <template #body="slotProps" >
                                        {{formatCurrency(slotProps.data.gst)}}
                                    </template>
                                </Column>
                                <Column field="roundOff" header="Round Off">
                                    <template #body="slotProps" >
                                        {{formatNumber(slotProps.data.roundOff)}}
                                    </template>
                                </Column>
                                <Column field="billRoundedValue" header="Value">
                                    <template #body="slotProps" >
                                        {{formatCurrency(slotProps.data.billRoundedValue)}}
                                    </template>
                                </Column>

                                <template #expansion="slotProps">
                                     <DataTable :value="slotProps.data.billItems">
                                        <Column field="" header="Sl No.">
                                            <template #body="slotProps" >
                                                {{slotProps.index + 1}}
                                            </template>
                                        </Column>
                                        <Column field="location" header="Location"></Column>
                                        <Column field="hsn" header="HSN"></Column>
                                        <Column field="model" header="Model No."></Column>
                                        <Column field="srPartNo" header="Sr/Part No."></Column>
                                        <Column field="installionDate" header="Installed Date"></Column>
                                        <Column field="servicingDate" header="Servicing Date"></Column>
                                        <Column field="cmcYear" header="CMC Year"></Column>
                                        <Column field="quantity" header="Qty"></Column>
                                        <Column field="rate" header="Rate">
                                            <template #body="slotProps" >
                                                {{formatCurrency(slotProps.data.rate)}}
                                            </template>
                                        </Column>
                                        <Column field="gstRate" header="GST(%)">
                                            <template #body="slotProps" >
                                                {{formatNumber(slotProps.data.gstRate)}}
                                            </template>
                                        </Column>
                                        <Column field="gst" header="GST">
                                            <template #body="slotProps" >
                                                {{formatCurrency(slotProps.data.gst)}}
                                            </template>
                                        </Column>
                                        <Column field="totalExGst" header="Ex-GST">
                                            <template #body="slotProps" >
                                                {{formatCurrency(slotProps.data.totalExGst)}}
                                            </template>
                                        </Column>
                                        <Column field="totalInclGst" header="Sub-Total">
                                            <template #body="slotProps" >
                                                {{formatCurrency(slotProps.data.totalInclGst)}}
                                            </template>
                                        </Column>

                                     </DataTable>
                                </template>

                            </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        

    </div>


</template>

<script>
import ProgressSpinner from 'primevue/progressspinner'
import Navbar from '../header/headerNavbar.vue'
import SideBar from '../sidebar/sideBarTwo.vue'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import { FilterMatchMode } from 'primevue/api'
import { getCmcBills } from '@/services/apiService'

export default {
    components:{
        Navbar,
        SideBar,
        ProgressSpinner,
        Button,
        DataTable,
        Column,
        InputText,
    },

    data(){
        return{
            spinnerLoadingStatus:false,
            filteredCmcBills:[],
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                billNo: { value: null, matchMode: FilterMatchMode.IN },
                item: { value: null, matchMode: FilterMatchMode.IN },          
                orderNo: { value: null, matchMode: FilterMatchMode.IN },
            },
            expandedRows:[],
            billDocData:null,
            billId:null,
        }
    },

    methods:{
        billDoc(id){
            this.billId = id;
            this.filteredCmcBills.map(this.getBillData);            
        },
        getBillData(element){ 
            if(element.id==this.billId)
                this.billDocData = element;            
                
        },
        formatCurrency(value) {
            if(value)
                return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
        },
        formatNumber(value) {
            if(value)
                return value.toLocaleString('en-IN');
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
        },
    },

    mounted(){
        this.spinnerLoadingStatus = true;
        getCmcBills().then((res)=>{            
            this.filteredCmcBills = res.data;            
            this.spinnerLoadingStatus = false;
        }).catch((error)=>{
            this.showErrorToast(error.response.data)
            this.spinnerLoadingStatus = false;
        })
    }
}
</script>

<style>

</style>