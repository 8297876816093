<template>
<Navbar/>
<SideBar/>
 
<div class="card main-content">
        <div class="progress-spinner" v-if="spinnerLoadingStatus">
            <ProgressSpinner></ProgressSpinner>
        </div>
        <div class="surface-ground ">
          <Card>
                
                <template #content>

                  <DataTable :value="filteredDcList" sortMode="multiple" ref="dcList" size="small" :loading="tableLoading" v-model:filters="filters" tableStyle="min-width: 50rem" v-model:expandedRows="expandedRows" dataKey="id" @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" paginator :rows="50" :rowsPerPageOptions="[10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" scrollable scrollHeight="500px">

                     <template #header >

                      <Card class="mb-3">
                        <template #title>Custom Filter</template>
                        <template #content>
                          <div class="formgrid grid">
                            <div class="field col">
                              <strong><label for="firstname2">Given Status:</label></strong>
                              <!-- <Dropdown v-model="selectedGivenStatus" :options="givenOptions" optionLabel="name" placeholder="Select an option" class="w-full" /> -->
   

                              <Dropdown v-model="selectedGivenStatus"  :options="givenOptions" placeholder="Select an option" class="p-column-filter w-full" :showClear="true" >
                                  <template #value="slotProps">
                                      <div v-if="slotProps.value" class="flex align-items-center">
                                          <div>{{ slotProps.value.name }}</div>
                                      </div>
                                      <span v-else>
                                          {{ slotProps.placeholder }}
                                      </span>
                                  </template>
                                  <template #option="slotProps">
                                      <Tag :value="getGivenValue(slotProps.option)" :severity="getGivenSeverity(slotProps.option)" />
                                  </template>
                              </Dropdown>


                            </div>
                            <div class="field col">
                              <strong><label for="firstname2">Receive Status:</label></strong>
                              <!-- <Dropdown v-model="selectedReceivedStatus" :options="receivedOptions" optionLabel="name" placeholder="Select an option" class="w-full" /> -->

                              <Dropdown v-model="selectedReceivedStatus"  :options="receivedOptions" placeholder="Select an option" class="p-column-filter w-full" :showClear="true" >
                                  <template #value="slotProps">
                                      <div v-if="slotProps.value" class="flex align-items-center">
                                          <div>{{ slotProps.value.name }}</div>
                                      </div>
                                      <span v-else>
                                          {{ slotProps.placeholder }}
                                      </span>
                                  </template>
                                  <template #option="slotProps">
                                      <Tag :value="getRecdValue(slotProps.option)" :severity="getRecdSeverity(slotProps.option)" />
                                  </template>
                              </Dropdown>

                            </div>
                            <div class="field col">
                              <strong><label for="firstname2">Billed Status:</label></strong>
                              <!-- <Dropdown v-model="selectedBilledStatus" :options="billedOptions" optionLabel="name" placeholder="Select an option" class="w-full" /> -->

                              <Dropdown v-model="selectedBilledStatus"  :options="billedOptions" placeholder="Select an option" class="p-column-filter w-full" :showClear="true" >
                                  <template #value="slotProps">
                                      <div v-if="slotProps.value" class="flex align-items-center">
                                          <div>{{ slotProps.value.name }}</div>
                                      </div>
                                      <span v-else>
                                          {{ slotProps.placeholder }}
                                      </span>
                                  </template>
                                  <template #option="slotProps">
                                      <Tag :value="getBilledValue(slotProps.option)" :severity="getBilledSeverity(slotProps.option)" />
                                  </template>
                              </Dropdown>

                            </div>
                            <!-- <div class="field col">
                              <strong><label for="firstname2">Date range:</label></strong>
                              <Calendar disabled v-model="selectedDateRange" selectionMode="range" :manualInput="false" placeholder="Select date range" class="w-full"/>
                            </div> -->

                            <div class="field col">
                              <strong><label class="w-full">Filter:</label></strong>
                              <div class="flex w-full">
                                <div class="flex-initial flex align-items-center justify-content-center w-full">
                                  <Button class="w-full mx-1 my-1" size="small" icon="pi pi-filter" severity="success" v-tooltip.top="'Filter'" raised @click="customFilter"/>
                                </div>
                                <div class="flex-initial flex align-items-center justify-content-center w-full">
                                  <Button class="w-full mx-1 my-1" size="small" icon="pi pi-filter-slash" severity="warning" v-tooltip.top="'Clear filter'" text raised @click="clearCustomFilter"/>
                                </div>
                              </div>
                            </div>

                            <div class="field col">
                              <strong><label for="firstname2">Filtered Value:</label></strong>
                              <InputNumber v-model="filteredDcValue" placeholder="Total DC value" class="w-full" locale="en-IN" :minFractionDigits="2" :maxFractionDigits="2" variant="filled" prefix="&#x20B9;" readonly/>
                            </div>

                          </div>
                            
                        </template>
                    </Card>

                      <div class="flex justify-content-between flex-wrap">
                          <div class="flex align-items-center justify-content-center text-xl">
                              Delivery Challan
                          </div>
                          <div class="flex align-items-center justify-content-center">
                              <router-link to="/delivery-challan-bulk" rel="noopener">
                                <Button label="Bulk Item DC" size="small" severity="info"  raised/>
                              </router-link>
                              <!-- <InputText v-model="filters['global'].value" placeholder="Keyword Search" /> -->
                              <Button icon="pi pi-external-link" label="Export" size="small" text raised class="mx-2" @click="exportData($event)" v-tooltip.top="'Export CSV'"/>
                              <InputText v-model="filterQuery" placeholder="Keyword Search" size="small"/>
                              <Button class="ml-2" icon="pi pi-filter" severity="success" raised size="small" @click="globalFilter" v-tooltip.top="'Search'"/>
                              <Button class="ml-2" icon="pi pi-filter-slash" size="small" outlined raised severity="success" @click="clearFilter" v-tooltip.top="'Clear result'"/>
                          </div>
                      </div>
                     </template>

                    <Column expander style="width: 5rem"/>
                    <Column field="dcNo" header="DC No.">
                       <template #body="slotProps">
                            <router-link @click="dcDoc(slotProps.data)" to="/dc-pdf" >{{slotProps.data.dcNo}}</router-link>
                        </template>
                    </Column>
                    <Column field="dcDate" header="DC Date">
                    </Column>
                    <Column field="dept.name" header="Dept"/>
                    <Column field="deptPo" header="Dept PO"/>
                    
                    <Column field="location.name" header="Location"/>

                    <Column field="dcValue" header="Value">
                      <template #body="slotProps">
                        {{formatCurrency(slotProps.data.dcValue)}}
                      </template>
                    </Column>
                    
                    <Column header="Given">
                      <template #body="slotProps">
                        <Button v-if="slotProps.data.givenStatus==0" size="small" label="Give Out" icon="pi pi-sign-out" class="px-2 py-1" severity="danger" text raised @click="showGiveOutDialog(slotProps.data)"></Button>
                          <Tag v-if="slotProps.data.givenStatus==1" value="Given" severity="success" />
                      </template>
                    </Column>
                    <Column header="Action">
                      <template #body="slotProps">
                        <div v-if="slotProps.data.recdStatus==0" class="flex">
                          <div class="flex-initial flex align-items-center justify-content-center">
                            <Button  icon="pi pi-file-edit" severity="success" raised rounded text @click="showEditChallanDialog(slotProps.data)" v-tooltip.top="'Edit DC date'" class="mr-1"/>
                          </div>
                          <div class="flex-initial flex align-items-center justify-content-center">
                            <!-- <Button v-if="slotProps.index==0" icon="pi pi-trash" severity="danger"  raised rounded v-tooltip.top="'Delete DC'"/> -->
                            <Button icon="pi pi-times" severity="danger"  raised rounded text v-tooltip.top="'Cancel DC'" @click="showCancelDialog(slotProps.data)"/>
                          </div>
                        </div>

                        <div v-if="slotProps.data.recdStatus==1" class="flex">
                          <div class="flex-initial flex align-items-center justify-content-center">
                            <Button  icon="pi pi-file-edit" severity="success" raised rounded text disabled class="mr-1"/>
                          </div>
                          <div class="flex-initial flex align-items-center justify-content-center">
                            <Button  icon="pi pi-times" severity="danger"  raised rounded text disabled />
                          </div>
                        </div>
                      </template>
                    </Column>

                    <Column field="recdStatus" header="Recd">
                      <template #body="slotProps">
                        <Button v-if="slotProps.data.givenStatus==1 && slotProps.data.recdStatus==0" size="small" label="Update Recd" class="px-2 py-1" severity="help" raised text @click="showRecdDcDialog(slotProps.data)"></Button>
                            <Tag v-if="slotProps.data.givenStatus==0" :value="getStatusRecd(slotProps.data)" :severity="getSeverityRecd(slotProps.data)" />
                            <Tag v-if="slotProps.data.recdStatus==1" value="Received" severity="success" />
                            
                        </template>
                    </Column>

                    <Column  header="Billed">
                      <template #body="slotProps">
                            <Tag v-if="slotProps.data.billStatus==0" value="Pending" severity="danger" />
                            <div class="billClass" v-if="slotProps.data.billStatus !=0">
                            <label  >{{slotProps.data.billStatus}}</label>
                            </div>
                        </template>
                    </Column>

                    <!-- for uploading signed delivery challan  -->
                    <!-- <Column header="Upload">
                      <template #body="slotProps">
                          <FileUpload v-if="slotProps.data.recdStatus==1" class="px-2 py-2" mode="basic" name="demo[]" url="/api/upload" accept="image/*" :maxFileSize="1000000" @upload="onDcUpload(slotProps.data)" />                          
                            <Tag v-if="slotProps.data.recdStatus==0" value="Update Recd" severity="danger" />
                        </template>
                    </Column> -->

                    <template #expansion="slotProps">
                      <DataTable :value="slotProps.data.dcItems" v-model:editingRows="editChildRow" editMode="row" :pt="{
                table: { style: 'min-width: 25rem' },
                column: {
                    bodycell: ({ state }) => ({
                        style:  state['d_editing']&&'padding-top: 0.6rem; padding-bottom: 0.6rem;'
                    })
                }
                }" @row-edit-save="onChildRowEditSave($event, slotProps.data.id)">
                        <Column field="name" header="Item"/>
                        <Column field="hsn" header="HSN"/>
                        <Column field="packing" header="Packing">
                          <template #body="slotProps">
                              {{ slotProps.data.packing }}
                          </template>
                          <template #editor="{ data, field }">
                              <InputText v-model="data[field]" />
                          </template>
                        </Column>
                        <Column field="qty" header="Quantity"/>
                        <Column field="modelNo" header="Model">
                          <template #body="slotProps">
                              {{ slotProps.data.modelNo }}
                          </template>
                          <template #editor="{ data, field }">
                              <InputText v-model="data[field]" />
                          </template>
                        </Column>
                        <Column field="partNo" header="Part/Sr No.">
                          <template #body="slotProps">
                              {{ slotProps.data.partNo }}
                          </template>
                          <template #editor="{ data, field }">
                              <InputText v-model="data[field]" />
                          </template>
                        </Column>
                        <Column field="batch_no" header="Batch">
                          <template #body="slotProps">
                              {{ slotProps.data.batch_no }}
                          </template>
                          <template #editor="{ data, field }">
                              <InputText v-model="data[field]" />
                          </template>
                        </Column>
                        <Column field="mfgDate" header="Mfg/Exp Date">
                          <template #body="slotProps">
                                    {{ slotProps.data.mfgDate }}/{{slotProps.data.expDate}}
                                </template>
                        </Column>
                        <Column header="Update" :rowEditor="true" style="width: 7%; min-width: 4rem" bodyStyle="text-align:center"></Column>
                        <Column field="remark" header="Bill Update">
                          <template #body="slotProps">
                            
                            <Button v-if="slotProps.data.remark != null" label="Show" size="small" class="px-2 py-2" severity="success" @click="showDeliveredStockDialog($event, slotProps.data)"/>
                            <!-- <Tag v-if="slotProps.data.remark == null" severity="secondary" value="NA" /> -->
                            <Chip v-if="slotProps.data.remark == null" label="NA"  />
                          </template>
                        </Column>

                      </DataTable>
                    </template>

                  </DataTable>

                  <!-- update dc status -->
                    <Dialog :visible="giveOutDcDialogVisibility" modal :closable="false" header="Give out Delivery Challan?" :style="{ width: '30rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                        
                            <div class="flex">
                                <div class="flex align-items-center justify-content-center pr-3"><i class="pi pi-sign-out" style="font-size: 2.5rem"></i></div>
                                <div class="flex align-items-center justify-content-center ">
                                    <p> DC No.: <strong>{{dcNo}}</strong><br> Once given, Dept PO stock would be updated.</p>
                                </div>
                              
                            </div>

                        <div class="flex justify-content-end flex-wrap">
                            <Button class="flex align-items-center justify-content-center my-1 mr-2" size="small" label="Cancel" outlined severity="secondary" @click="giveOutDcDialogVisibility=false"></Button>
                            <Button class="my-1 py-2" label="Yes" severity="success" @click="updateGivenAndStock" />
                        </div>
                    </Dialog>
                    <!-- update dc status end -->

                    <!-- update recd dc -->
                    <Dialog :visible="recdDcDialogVisibility" modal :closable="false" header="Received Signed Delivery Challan?" :style="{ width: '30rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                        
                            <div class="flex">
                                <div class="flex align-items-center justify-content-center pr-3"><i class="pi pi-sign-in" style="font-size: 2.5rem"></i></div>
                                <div class="flex align-items-center justify-content-center ">
                                    <p> DC No.: <strong>{{dcNo}}</strong><br> Once received, Challan won't be editable.</p>
                                </div>
                              
                            </div>

                        <div class="flex justify-content-end flex-wrap">
                            <Button class="flex align-items-center justify-content-center my-1 mr-2" size="small" label="Cancel" outlined severity="secondary" @click="recdDcDialogVisibility=false"></Button>
                            <Button class="my-1 py-2" label="Yes" severity="success" @click="updateRecdChallan" />
                        </div>
                    </Dialog>
                    <!-- update recd dc end -->

                  <Dialog :visible="editDcDialogVisibility" modal :closable="false" :header="dialogHeader" :style="{ width: '45rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

                    <div class="field grid">
                        <label class="col-fixed" style="width:100px">DC No.:</label>
                          <div class="col">
                              <label>
                                <strong>
                                  <em>
                                    {{dc.dcNo}}
                                  </em>
                                </strong>
                              </label>
                          </div>
                      </div>

                    <div class="field grid">
                        <label class="col-fixed" style="width:100px">Department:</label>
                          <div class="col">
                              <label>
                                <strong>
                                  <em>
                                    {{dc.dept.name}}
                                  </em>
                                </strong>
                              </label>
                          </div>
                      </div>

                    <div class="field grid">
                        <label class="col-fixed" style="width:100px">Location:</label>
                          <div class="col">
                              <label>
                                <strong>
                                  <em>
                                    {{dc.location.name}}
                                  </em>
                                </strong>
                              </label>
                          </div>
                      </div>

                      <div class="field grid">
                        <label class="col-fixed" style="width:100px">DC Date:</label>
                          <div class="col">
                              <Calendar v-model="newdcDate" class="w-full" dateFormat="dd/mm/yy"/>
                          </div>
                      </div>
                                                        
                      <div class="flex justify-content-between flex-wrap">
                        <div class="flex align-items-center justify-content-center">
                          <label style="font-size:14px;">Note:<strong>DC No. would be updated to a new DC No.</strong></label>
                        </div>
                        <div class="flex align-items-center justify-content-center">
                          <Button class="flex align-items-center justify-content-center mx-1 px-2 my-1" size="small" label="Update" icon="pi pi-refresh" severity="success" @click="handleUpdateDc"></Button>
                          <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" outlined severity="secondary" @click="editDcDialogVisibility=false"></Button>
                        </div>
                      </div>
                  </Dialog>

                  <!-- cancel dc dialog statrt -->

                  <Dialog :visible="cancelDcDialogVisibility" modal :closable="false" header="Are you sure?" :style="{ width: '30rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                        
                            <div class="flex">
                                <div class="flex align-items-center justify-content-center pr-3"><i class="pi pi-exclamation-triangle" style="font-size: 2.5rem"></i></div>
                                <div class="flex align-items-center justify-content-center ">
                                    <p> DC No.: <strong>{{dcNo}}</strong>
                                    <br>This will cancel the Delivery Challan!
                                    <br>Items would be revoked to previous state. </p>
                                </div>                              
                            </div>
                            <div class="formgrid grid">
                              <div class="field col">
                                <label >Remarks:</label>
                                <Textarea v-model="cancelRemark" rows="5" cols="45" />
                              </div>
                            </div>

                        <div class="flex justify-content-end flex-wrap">
                            <Button class="my-1 py-2 mr-2" label="Cancel DC" severity="danger" @click="cancelDeliveryChallan" />
                            <Button class="flex align-items-center justify-content-center my-1 " size="small" label="Close" outlined severity="secondary" @click="cancelDcDialogVisibility=false"></Button>
                        </div>
                    </Dialog>
                    <!-- cancel dc dialog end -->

                            <OverlayPanel  ref="itemRemarkOp" dismissable>
                                    <h4> <label class="text-cyan-500">Updated when billed :</label> {{updatedItemName}}</h4>

                                    <table width="350px;">
                                      <tr>
                                        <th>
                                          Prev Qty:
                                        </th>
                                        <td>
                                          <label class="text-orange-500">{{itemPrevQty}}</label>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>
                                          Remarks:
                                        </th>
                                        <td>
                                          <label >{{dcRemark}}</label>
                                        </td>
                                      </tr>
                                    </table>
                            </OverlayPanel>
                </template>
          </Card>
        </div>

</div>

</template>

<script>
import ProgressSpinner from 'primevue/progressspinner'
import Card from 'primevue/card'
import Navbar from '../header/headerNavbar.vue'
import Column from 'primevue/column'
import { cancelDc, getDeliveryChallan, updateDc, updateDcStatusAndStock, updateGivenDc, updateRecdDc } from '@/services/apiService'
import { FilterMatchMode } from 'primevue/api'
import DataTable from 'primevue/datatable'
import { useDcStore } from '@/stores/dcStore';
import Tag from 'primevue/tag'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Calendar from 'primevue/calendar'
import OverlayPanel from 'primevue/overlaypanel'
import Chip from 'primevue/chip'
import Textarea from 'primevue/textarea'
import { useBillStore } from '@/stores/billStore'
import Dropdown from 'primevue/dropdown'
import InputNumber from 'primevue/inputnumber'
// import FileUpload from 'primevue/fileupload'
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
  components:{
    Navbar,
    SideBar,
      ProgressSpinner,
      Card,
      Column,
      DataTable,
      Tag,
      InputText,
      Button,
      Dialog,
      Calendar,
      OverlayPanel,
      Chip,
      // FileUpload,
      Textarea,
    Dropdown,
    InputNumber,
    },

    data(){
      return{
        expandedRows:[],
        spinnerLoadingStatus:false,
        dcList:[],
        filterQuery:null,
        filteredDcList:[],
        filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                dcNo: { value: null, matchMode: FilterMatchMode.IN },
                'dept.name':{ value: null, matchMode: FilterMatchMode.IN },
                billStatus: { value: null, matchMode: FilterMatchMode.IN },
            },
        editParentRow:[],
        editDcDialogVisibility:false,
        dc:null,
        dcNo:null,
        newdcDate:null,
        giveOutDcDialogVisibility:false,
        tableLoading:false,
        pagination:null,
        firstRecord:null,
        lastRecord:null,
        totalRecord:null,
        perPageRecord:10,
        recdDcDialogVisibility:false,
        dcRemark:null,
        itemPrevQty:0,
        updatedItemName:null,
        cancelDcDialogVisibility:false,
        cancelRemark:null,
        editChildRow:null,
        selectedGivenStatus:null,
        selectedReceivedStatus:null,
        selectedBilledStatus:null,
        selectedDateRange:null,
        givenOptions:[
          {id:'1', name:'Given', icon:'pi pi-sign-out', color:'green'}, 
          {id:'0', name:'Pending', icon:'pi pi-clock', color:'red'}
        ],
        receivedOptions:[
          {id:'1', name:'Received'}, 
          {id:'0', name:'Not Received'}
        ],
        billedOptions:[
          {id:'1', name:'Billed'}, 
          {id:'0', name:'Pending Billing'}
        ],
        filteredDcValue:0.0,
      }
    },

    methods:{
      getDcList(){
        this.spinnerLoadingStatus = true;
        const query = '?cancel[eq]=0'
        getDeliveryChallan(query).then((res)=>{
          this.dcList = res.data;
          // let dcAmt = 0;
          this.filteredDcList = [];
          this.dcList.forEach(e => {
            let dcAmt = 0;
            e.dcItems.forEach(l => {
              dcAmt += (l.deptRate * l.qty) + (((l.deptRate * l.qty)/100)*l.gstRate);
            });
            this.filteredDcList.push({
                  ...e,
                  dcValue:dcAmt,
                });
            
          });

          this.dcList = this.filteredDcList;

          this.spinnerLoadingStatus = false;
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
        });
      },
      dcDoc(data){
            const store = useDcStore(); 
            store.$patch({
                data:data,
            })
      },
      showGiveOutDialog(data){
        this.giveOutDcDialogVisibility = true;
        this.dc = data;
        this.dcNo = data.dcNo;
      },
      showRecdDcDialog(data){
        this.recdDcDialogVisibility = true;
        this.dc = data;
        this.dcNo = data.dcNo;
      },
      updateGivenAndStock(){
        const data = {
          'dc_id':this.dc.id,
          'dc_items':this.dc.dcItems,
          'location':this.dc.location,
        }
        
        this.giveOutDcDialogVisibility = false;
        this.spinnerLoadingStatus = true;

        updateDcStatusAndStock(data).then((res)=>{
          this.showSuccessToast(res.data);
          this.dc = null;
          this.getDcList();
          this.spinnerLoadingStatus = false;
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
          this.spinnerLoadingStatus = false;
          this.dc = null;
        })
      },
      updateRecdChallan(){
        this.recdDcDialogVisibility = false;
        this.spinnerLoadingStatus = true;
        updateRecdDc({id:this.dc.id}).then((res)=>{
          this.showSuccessToast(res.data);
          this.spinnerLoadingStatus = false;
          this.getDcList();
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
          this.spinnerLoadingStatus = false;
        })
      },
      showEditChallanDialog(dcData){
        this.dc = dcData;
        this.newdcDate = dcData.dcDate;
        this.editDcDialogVisibility = true;
      },
      handleUpdateDc(){
        if(this.dc.dcDate==this.newdcDate){
          this.editDcDialogVisibility = false;
          return;
        }
        const data = {
          'dcDate':this.newdcDate.getFullYear()+'-'+(this.newdcDate.getMonth()+1)+'-'+this.newdcDate.getDate(), 
        }

        this.editDcDialogVisibility = false;

        this.spinnerLoadingStatus = true;

        updateGivenDc(data, this.dc.id).then((res)=>{
          this.showSuccessToast(res.data);
          this.spinnerLoadingStatus = false;
          this.getDcList();
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
          this.spinnerLoadingStatus = false;
        })
      },
      onDcUpload(data){
        console.log('upload doc');
        console.log(data);
      },
      showCancelDialog(data){
        this.cancelDcDialogVisibility = true;
        this.dc = data;
        this.dcNo = data.dcNo;
        this.cancelRemark = null;
      },
      cancelDeliveryChallan(){
        const data = {
          'dcId':this.dc.id,
          'location':this.dc.location.locationId,
          'dcNo':this.dc.dcNo,
          'dcItems':this.dc.dcItems,
          'givenStatus':this.dc.givenStatus,
          'remarks':this.cancelRemark,
          'deptId':this.dc.dept.id,
        }

        this.cancelDcDialogVisibility = false;

        this.spinnerLoadingStatus = true;

        cancelDc(data).then((res)=>{
          this.showSuccessToast(res.data);
          this.getDcList();
          this.spinnerLoadingStatus = false;
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
          this.spinnerLoadingStatus = false;
        })

      },
      showDeliveredStockDialog(event, data){
          this.updatedItemName = data.name;
          this.dcRemark = data.remark;
          this.itemPrevQty = data.prevQty;
          this.$refs.itemRemarkOp.toggle(event);
      },
      onChildRowEditSave(event, dcId){
        
            const data = {
                'dcId':dcId,
                'id':event.data.id,
                'oldPacking':event.data.packing,
                'oldBatch':event.data.batch_no,
                'oldModelNo':event.data.modelNo,
                'oldPartNo':event.data.partNo,
                'newPacking':event.newData.packing,
                'newBatch':event.newData.batch_no,
                'newModelNo':event.newData.modelNo,
                'newPartNo':event.newData.partNo,
            }

            this.spinnerLoadingStatus = true;
            updateDc(data).then((res)=>{
                this.showSuccessToast(res.data);
                this.spinnerLoadingStatus = false;
                this.getDcList();
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            });
      },
      getStatus(data){
            switch (data.givenStatus) {
                case '1':
                    return 'Given'
                case '0':
                    return 'Not Given';
                default:
                    break;
            }
      },
      getSeverity(data){
          switch (data.givenStatus) {
              case '1':
                  return 'success'
              case '0':
                  return 'danger';
              default:
                  break;
          }
      },
      getStatusRecd(data){
          switch (data.recdStatus) {
              case '1':
                  return 'Received'
              case '0':
                  return 'Not Received';
              default:
                  break;
          }
      },
      getSeverityRecd(data){
          switch (data.recdStatus) {
              case '1':
                  return 'success'
              case '0':
                  return 'danger';
              default:
                  break;
          }
      },
      getGivenValue(data){
        switch (data.id) {
                case '1':
                    return 'Given'
                case '0':
                    return 'Pending';
                default:
                    break;
            }
      },
      getGivenSeverity(data){
        switch (data.id) {
                case '1':
                    return 'success'
                case '0':
                    return 'danger';
                default:
                    break;
            }
      },
      getRecdValue(data){
        switch (data.id) {
                case '1':
                    return 'Received'
                case '0':
                    return 'Not Received';
                default:
                    break;
            }
      },
      getRecdSeverity(data){
          switch (data.id) {
                case '1':
                    return 'success'
                case '0':
                    return 'danger';
                default:
                    break;
            }
      },
      getBilledValue(data){
        switch (data.id) {
                case '1':
                    return 'Billed'
                case '0':
                    return 'Pending Billing';
                default:
                    break;
            }
      },
      getBilledSeverity(data){
          switch (data.id) {
                case '1':
                    return 'success'
                case '0':
                    return 'danger';
                default:
                    break;
            }
      },
      showErrorToast(error){
          this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
      },
      showSuccessToast(data){
          this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
      },
      billDoc(data){
            const store = useBillStore();
            store.$patch({
                data:data,
            })
        },
      globalFilter(){
            
            this.filteredDcList = [];
            if(this.filterQuery!=null){
                this.dcList.forEach(e => {     
                    if (e.dcNo.includes(this.filterQuery) || e.deptPo.includes(this.filterQuery) || e.dept.name.includes(this.filterQuery) || e.location.name.includes(this.filterQuery) || e.billStatus.includes(this.filterQuery)) { 
                      this.filteredDcList.push(e); 
                    }
                    else{
                        e.dcItems.every(el => { 
                            if(el.name.includes(this.filterQuery) || (el.batch_no ? el.batch_no.includes(this.filterQuery) : false ) || (el.partNo ? el.partNo.includes(this.filterQuery) : false) || (el.modelNo ? el.modelNo.includes(this.filterQuery) : false)){
                                this.filteredDcList.push(e);
                                return false;
                            }
                            return true;
                        });
                    }
                });
            }
        },
        clearFilter(){
            this.filteredDcList = this.dcList;
            this.filterQuery = null;
        },
      customFilter(){
        let temp = this.dcList; 
        this.filteredDcList = [];
        this.filteredDcValue = 0;
        if(this.selectedGivenStatus || this.selectedBilledStatus || this.selectedBilledStatus){
          temp.forEach(e => {
            let dcAmt = 0;
             if((this.selectedGivenStatus ? e.givenStatus==this.selectedGivenStatus.id : true) && (this.selectedReceivedStatus ? e.recdStatus==this.selectedReceivedStatus.id : true) && (this.selectedBilledStatus ? ((this.selectedBilledStatus.id==1 && e.billStatus!=0) || (this.selectedBilledStatus.id==0 && e.billStatus==this.selectedBilledStatus.id)) : true)){
               e.dcItems.forEach(l => {
                 this.filteredDcValue += (l.deptRate * l.qty) + (((l.deptRate * l.qty)/100)*l.gstRate);
                 dcAmt += (l.deptRate * l.qty) + (((l.deptRate * l.qty)/100)*l.gstRate);

              });

                this.filteredDcList.push({
                  ...e,
                  dcValue:dcAmt,
                  });
             }
          });
        }
      },
      clearCustomFilter(){
        this.selectedGivenStatus = null;
        this.selectedReceivedStatus = null;
        this.selectedBilledStatus = null;
        this.selectedDateRange = null;
        this.filteredDcValue = 0;
        this.filteredDcList = this.dcList; 
      },
      formatCurrency(value) {
        if(value)
            return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
    },
      exportData(){
        this.$refs.dcList.exportCSV();
      }
    },

    mounted(){
      this.getDcList();
    }
}
</script>

<style scoped>

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}

.billClass {
  border-radius: 10px;
  background-color: rgb(216, 210, 210);
  padding: 2px 5px;
  font-size: 11px;
  width: 120px;
}

</style>