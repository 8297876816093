<template>

    <div class="main-content">
        <Card style="width:70%">
                <template #content>
                    <div class="flex justify-content-start">
                        <Button label="Print" text raised size="small" @click="printPdf()" class="mr-2"/>
                        
                    </div>
                    <hr>

                    <table class="white-bg " width="100%" style="font-size:9px; margin-top:15px;" id="cmc_bill_doc">
            <thead >
                        <tr>
                            <td colspan="11" style="text-align: center; font-size: 14px; padding: 1% 0;">
                                <label ><strong>Service Invoice</strong></label>
                            </td>
                        </tr>

                        <tr>
                          <td rowspan="2" colspan="5" style="padding-left:1%; padding-bottom:2%;">
                              <label style="font-size:16px;"><strong>Arengh Medical Supplier</strong></label><br>
                              HAWAKHANA, TURA, South West Garo Hills, Meghalaya, 794001<br>
                              GSTIN/UIN: 17ABEFA6546N1Z1<br>
                              PAN: ABEFA6546N <br>
                              State Name: Meghalaya, Code 17
                          </td>
                          <td colspan="3" class="header-cell-padding">Service Invoice No. <br> <strong> {{billNo}}</strong></td>
                          <td colspan="3" class="header-cell-padding">Dated <br> <strong>{{billDate}}</strong></td>
                        </tr>
                        <tr>
                            <td colspan="3" class="header-cell-padding">Supplier's Ref. <br>
                                <strong>
                                    {{deliveryChallans}}
                                </strong>
                            </td>
                            <td colspan="3" class="header-cell-padding" style="padding-bottom:1%" >Other References<br> 
                            <strong>
                                {{dcDates}}
                            </strong>
                            </td>
                        </tr>
                        
                        <tr>
                            <td style="padding-left:1%; padding-bottom:3%;" colspan="5" > Buyer <br> <strong> The {{buyerDesignation}}</strong>
                                <br>
                                {{buyerDept}} <br>
                                State Name: {{buyerState}}, Code: {{buyerStateCode}}
                            </td>
                            <td colspan="3" class="header-cell-padding wrap-long-po">Buyer's Order No. <br><strong>{{deptPoNo}}</strong></td>
                            <td colspan="3" class="header-cell-padding">Dated <strong><br> {{deptPoDate}}</strong> </td>

                        </tr>

                         <tr>
                          <th class="sl-no">Sl <br> No.</th>
                          <th class="descrip-goods">Description of Goods</th>  
                          <th class="hsn-sac">HSN/SAC</th>
                          <th class="gst-rate">GST<br>(%)</th>
                          <th class="sr-prt-no">Sr No/ <br> Batch No</th>
                          <th class="model-no">Model No.</th>
                          <th class="location-class">Location</th>
                          <th class="install-date">Installation Date</th>
                          <th class="qty-class">Qty</th>
                          <th class="rate-class">Rate</th>
                          <th class="sub-total">Amount</th>
                        </tr>
                        
            </thead>
            <tbody >               
            
                    <tr v-for="(item, index) in items" :key="index" valign="top">
                            <td align="center" class="border-hidden" style="padding-bottom: 1%; vertical-align: top;">
                                    {{item.slNo}}                                                  
                            </td>
                        <td align="left" class="border-hidden" style="vertical-align: top; padding-left:3px; padding-top:3px;">
                            <!-- <strong> -->
                                {{item.name}}
                            <!-- </strong>  -->
                        </td>
                        <td align="center" class="border-hidden" style="vertical-align: top;">
                            {{item.hsn}}
                        </td>
                        <td align="center" class="border-hidden" style="vertical-align: top;">
                            {{item.gstRate}}
                        </td>
                        <td align="center" class="border-hidden wrap-word" style="vertical-align: top;">
                            {{item.srPartNo}}
                        </td>
                        <td align="center" class="border-hidden" style="vertical-align: top;">
                            {{item.model}}
                        </td>
                        <td align="left" class="border-hidden" style="vertical-align: top; padding-left:3px;">
                            {{item.installedLocation}}
                        </td>
                        <td align="center" class="border-hidden" style="vertical-align: top;">
                            {{item.installionDate}}
                        </td>
                        <td align="center" class="border-hidden" style="vertical-align: top;">
                            <strong>
                                {{formatCurrency(item.quantity)}} <br>
                            </strong>
                        </td>
                        <td align="right" class="border-hidden" style="vertical-align: top;">
                            {{formatCurrencyTwoDecimal(item.rate)}}
                        </td>
                        <td align="right" class="border-hidden" style="vertical-align: top;">
                            {{formatCurrencyTwoDecimal(item.totalExGst)}}
                        </td>
                    </tr>                
                        
                <tr >
                    <td><div id="additional-padding"></div></td>
                    <td><div id="additional-padding"></div></td>
                    <td><div id="additional-padding"></div></td>
                    <td><div id="additional-padding"></div></td>
                    <td><div id="additional-padding"></div></td>
                    <td><div id="additional-padding"></div></td>
                    <td><div id="additional-padding"></div></td>
                    <td><div id="additional-padding"></div></td>
                    <td><div id="additional-padding"></div></td>
                    <td><div id="additional-padding"></div></td>
                    <td><div id="additional-padding"></div></td>
                </tr>

                <tr>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td colspan="2">
                        <strong>
                            Taxable Value
                        </strong>
                    </td>
                    <td align="right">
                        <strong>
                            {{formatCurrencyTwoDecimal(taxableValue)}}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td colspan="2">
                        <strong>
                            CGST
                        </strong>
                    </td>
                    <td align="right">
                        <strong>
                            {{formatCurrencyTwoDecimal(totalCgst)}}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td colspan="2">
                        <strong>
                            SGST
                        </strong>
                    </td>
                    <td align="right">
                        <strong>
                            {{formatCurrencyTwoDecimal(totalSgst)}}
                        </strong>
                    </td>
                </tr>
                <tr v-if="roundOff!=0 || roundOff!=null" >
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td colspan="2">
                        <strong>
                            Round Off
                        </strong>
                    </td>
                    <td align="right">
                        <strong>
                            {{(roundOff)}}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td colspan="8"></td>
                    
                    <td colspan="2">
                        <strong>
                            Total
                        </strong>
                    </td>
                    <td align="right">
                        <strong>
                            {{formatCurrencyTwoDecimal(totalValue)}}
                        </strong>
                    </td>
                </tr>

                <tr>
                    <td colspan="11" class="footer-padding">
                        <div class="flex justify-content-between flex-wrap">

                            <div class="flex align-items-center justify-content-center">
                                <strong>Amount (in words) :</strong> <em>{{fetchNumberToWords(totalValue)}}</em>
                            </div>
                            <div class="flex align-items-center justify-content-center">
                                E. & O.E
                            </div>

                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="5" rowspan="2" align="center">
                        HSN/SAC <br>
                    </td>
                    <td rowspan="2" align="center">Taxable Value</td>
                    <td colspan="2" align="center">Central Tax</td>
                    <td colspan="2" align="center">State Tax</td>
                    <td rowspan="2" align="center">Total Tax Amount</td>
                </tr>
                <tr>
                    <td align="center">Tax Rate </td>
                    <td align="center">Amount</td>
                    <td align="center">Tax Rate </td>
                    <td align="center">Amount</td>
                </tr>
                <tr v-for="(item, index) in subTotalArr" :key="index">
                    <td colspan="5" align="center">
                        {{item.hsn}}
                    </td>
                    <td align="center">
                        {{formatCurrencyTwoDecimal(item.taxableAmt)}}
                    </td>
                    <td align="center">
                        {{item.gstRate/2}} (%)
                    </td>
                    <td align="center">
                        {{formatCurrencyTwoDecimal(item.cgstAmt)}}
                    </td>
                    <td align="center">
                        {{item.gstRate/2}} (%)
                    </td>
                    <td align="center">
                        {{formatCurrencyTwoDecimal(item.sgstAmt)}}
                    </td>
                    <td align="center">
                        {{formatCurrencyTwoDecimal(item.gstAmt)}}
                    </td>
                </tr>
                <tr>
                    <td colspan="5" align="right" class="pr-2">
                        <strong>
                            Total
                        </strong>
                    </td>
                    <td align="center">
                         <strong>
                            {{formatCurrencyTwoDecimal(taxableValue)}}
                        </strong>
                    </td>
                    <td align="center">                     
                    </td>
                    <td align="center">
                        <strong>
                            {{formatCurrencyTwoDecimal(totalCgst)}}
                        </strong>
                    </td>
                    <td align="center">                        
                    </td>
                    <td align="center">
                        <strong>
                            {{formatCurrencyTwoDecimal(totalSgst)}}
                        </strong>
                    </td>
                    <td align="center">
                        <strong>
                            {{formatCurrencyTwoDecimal(totalCgst + totalSgst)}}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td colspan="5" class="footer-padding">
                        <strong>Tax Amount (in words) :</strong> <em>{{fetchNumberToWords(totalCgst + totalSgst)}}</em>
                    </td>
                    <td colspan="3" class="footer-padding">
                        <strong>
                        Company' Bank Details <br>
                        Bank Name <br>
                        A/c No. <br>
                        Branch & IFSC Code
                        </strong>
                    </td>
                    <td colspan="3" class="footer-padding">
                        <br>
                        <strong>
                        : Federal Bank <br>
                        :11900200013538	<br>
                        :FDRL0001190
                        </strong>			
                    </td>
                </tr>
                <tr>
                    <td colspan="5" class="footer-padding">
                        <u> Declaration</u> 
                        <br>
                        We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.	
                        <br>
                        <br>
                        <br>
                        <br>				
                    </td>
                    <td colspan="6" align="right" class="footer-padding">
                        <strong>
                            For Arengh Medical Supplier				
                        </strong> 
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        Authorised Signatory	
                    </td>
                </tr>
                <tr>
                    <td colspan="11" class="footer-caption-two">This is a Computer Generated Document</td>
                </tr>
            </tbody>
        </table>

                </template>
        </Card>
    </div>

</template>

<script>
import Card from 'primevue/card';
import { getCmcDetails } from '@/services/apiService';
import Button from 'primevue/button';
import { numberToWord } from '../../services/converter/numberToWords';

export default {
    
    components:{
        Card,
        Button,
    },

    data(){
      return{
            billNo:null,
            billDate:null,
            deptPoNo:null,
            deptPoDate:null,
            buyerDept:null,
            buyerDesignation:null,
            buyerState:null,
            buyerStateCode:null,
            items:[],
            taxableValue:0,
            totalCgst:0,
            totalSgst:0,
            roundOff:0,
            totalValue:0,
            subTotalArr:[],
            slNo:0,
        }
    },

    methods:{
        fetchCmcDetails(){
            getCmcDetails(this.$route.params.id).then((res)=>{
                this.billNo = res.data.billNo
                this.billDate = res.data.billDate
                this.deptPoNo = res.data.orderNo
                this.deptPoDate = res.data.orderDate
                this.buyerDesignation = res.data.department.head
                this.buyerState = res.data.department.state
                this.buyerStateCode = res.data.department.code  
                this.buyerDept = res.data.department.name;
                this.taxableValue = res.data.taxable;
                this.totalCgst = res.data.cgst
                this.totalSgst = res.data.sgst
                this.roundOff = this.formatCurrencyTwoDecimal(res.data.roundOff)                
                this.totalValue = res.data.taxable + res.data.cgst + res.data.sgst + res.data.roundOff;

                let temp = res.data.billItems
                temp.forEach((e, key) => {

                if(key>0){                    
                    let item = null;
                     item = this.subTotalArr.find((l) => l.gstRate == e.gstRate);                   
                    
                    if(item){
                        if(!item.hsn.includes(e.hsn)){                            
                            item.hsn += ', '+e.hsn;
                            item.cgstAmt += (e.gst/2);
                            item.sgstAmt += (e.gst/2);
                            item.gstAmt += e.gst;
                            item.taxableAmt += e.totalExGst;
                        }
                        else{                            
                            item.cgstAmt += (e.gst/2);
                            item.sgstAmt += (e.gst/2);
                            item.gstAmt += e.gst;
                            item.taxableAmt += e.totalExGst;
                        }
                    }
                    else{
                        this.subTotalArr.push({
                            'hsn':e.hsn,
                            'gstRate':e.gstRate,
                            'cgstAmt':e.gst/2,
                            'sgstAmt':e.gst/2,
                            'gstAmt':e.gst,
                            'taxableAmt':e.totalExGst,
                        });
                    }

                    // end club hsn

                    // for clubbing equipment details
                    e.slNo = null
                    e.name = null
                    e.hsn = null
                    e.gstRate = null
                    e.model = null
                    e.quantity = null
                    e.rate = null
                    e.totalExGst = null

                    this.items.push(e); 

                    // end of clubbing items

                    // previous club hsn positiion
                }
                if(key==0){
                    // club together hsn, gst rate and sub total
                    this.subTotalArr.push({
                        'hsn':e.hsn,
                        'gstRate':e.gstRate,
                        'cgstAmt':e.gst/2,
                        'sgstAmt':e.gst/2,
                        'gstAmt':e.gst,
                        'taxableAmt':e.totalExGst,
                    });

                    e.slNo = ++this.slNo;
                    e.name = res.data.item
                    let firstQty = e.quantity;
                    let firstExGst = e.totalExGst

                    res.data.billItems.forEach(el => {                        
                        e.quantity += el.quantity
                        e.totalExGst += el.totalExGst
                    });

                    e.quantity -= firstQty
                    e.totalExGst -= firstExGst
                    this.items.push(e); 
                }
            });
                
            }).catch((error)=>{
                this.showErrorToast(error.response.data)
            })
        },
        formatCurrencyTwoDecimal(value) {            
            if(value){
                return value.toLocaleString('en-IN', {maximumFractionDigits:2, minimumFractionDigits:2});
            }
        },
        formatCurrency(value) {
            if(value){
                return value.toLocaleString('en-IN');
            }
        },
        formatCurrencyFourDecimal(value) {
            if(value){
                return value.toLocaleString('en-IN', {maximumFractionDigits:4, minimumFractionDigits:4});
            }
        },
        fetchNumberToWords(n){
            return numberToWord(n)            
        },
        printPdf(){
            window.print();
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
        },
    },

    mounted(){
        this.fetchCmcDetails();
    }

}
</script>

<style scoped>

table {
    table-layout: auto;
    min-width: 100%;
    border-collapse: collapse;
    page-break-before: always;
}

 table, th, td {
    border: 0.5px solid black;
    border-collapse: collapse;
    border-color: rgb(170, 170, 170);
  }

.white-bg{
    background-color: white;
}

.hide-head-border {
    border-top-style: hidden;
  }

.header-cell-padding{
      padding-left: 1%;
      padding-right: 1%;
  }

   #additional-padding {
    grid-template-columns: 100%;
    z-index: 1;
    padding-bottom: 10%;
    min-height: 5vh;
    max-height: 5vh;
  }

th.sl-no{
    width: 2%;
}

th.descrip-goods{
    width: 20%;
}

th.hsn-sac{
    width: 5%;
}

th.gst-rate{
    width: 5%;
}

th.sr-prt-no{
    width: 10%;
}

th.model-no{
    width: 10%;
}

  th.location-class{
    width: 15%;
  }

  th.install-date{
    width: 8%;
  }

  th.qty-class{
    width: 10%;
  }

    th.rate-class{
        width: 10%;
    }

th.sub-total{
    width: 10%;
}

.border-hidden {
    border-bottom-style: hidden;
  }

.footer-caption-two{
    border: 0.5px solid rgb(170, 170, 170);
      text-align: center;
  }

.footer-padding {
    padding: 1% 1%;
  }

@media print {
  body {
    visibility: hidden;
  }
  #cmc_bill_doc {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
}

</style>