import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../components/auth/LoginComponent'
import Dashboard from '../components/views/DashboardComponent'
import Logout from '../components/auth/LogoutComponent';
import Department from '../components/department/departmentList';
import AddRoc from '../components/roc/addRoc';
import RocList from '../components/roc/rocList';
// import AddManufacturer from '../manufacturer/addmanufacturers';
import ManufacturerList from '../components/manufacturer/listManufacturer.vue';
import DeptPoList from '../components/departmentPo/deptPoList';
import DeptPo from '../components/departmentPo/addDeptPo';
import OemPO from '../components/oem/addOemPo';
import OemPoList from '../components/oem/oemPoList';
import OemPoDoc from '../components/oem/oemPoDoc';
import TrialOemPdf from '../components/oem/poDocTableTrial';
import Pincode from '../components/testPincode';
// import { getUser } from '@/services/apiService';
import TermsAndConditions from '../components/templates/termsAndConditions';
import Categories from '../components/categories/categoryList';
import Store from '../components/store/storeList';
import DeliveryChallan from '../components/deliveryChallan/deliveryChallan';
import Inventory from '../components/store/inventoryList';
import OemPoDrafts from '../components/oem/oemPoDraftList';
import OemPoCancelled from '../components/oem/oemPoCancelList';
import InvoiceDetails from '../components/invoice/invoiceDetails';
import NewInvoice from '../components/invoice/newInvoice';
import StockArrival from '../components/stock/stockArrival';
import DcDoc from '../components/deliveryChallan/deliveryChallanDoc';
import DeliveryChallanBulk from '../components/deliveryChallan/bulkDeliveryChallan';
import AddBill from '../components/bill/newBill';
import BillList from '../components/bill/billList';
import BillPdf from '../components/bill/billDoc';
import CancelledDC from '../components/deliveryChallan/cancelledDeliveryChallan';
import BillListDraft from '../components/bill/billListDraft';
import BillListCancelled from '../components/bill/billListCancelled';
import SideBarTwo from '../components/sidebar/sideBarTwo';
import EquipmentInstallation from '../components/cmcs/equipInstallations';
import EquipmentCmcDetails from '../components/cmcs/equipmentCmcDetails';
import MisReport from '../components/reports/reportsTwo';
// import CmcEquipment from '../components/cmcs/equipmentCmc';
import NewServicingBill from '../components/cmcs/cmcBilling';
import ServicingBills from '../components/cmcs/cmcBills';
import CmcBillDoc from '../components/cmcs/cmcBillDoc';

const guard = function(to, from, next){
    const token = localStorage.getItem('bearer-token')
    // const {authorize}  = to.meta;

        if(token){
            // getUser().then((res)=>{
            //   const role = res.data.data;
            //     if(authorize.includes(role)){
            //         next();
            //     }
            //     if(!authorize.includes(role)){
            //         next('/dashboard');
            //     }
            // }).catch((error)=>console.log(error));
            next();
        }
        else{
            next('/');
        }
  }

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
    },
    { 
        path: '/dashboard', 
        name: 'dashboard',
        component: Dashboard,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
        // meta:{authorize:['admin', 'deo']},
    },
    {
        path: '/departments',
        name: 'departments',
        component:Department,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
        // meta:{authorize:['admin', 'deo']},
    },
    { 
        path: '/add-roc', 
        name: 'add-roc',
        component: AddRoc,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
        // meta:{authorize:['admin']},
        
    },
    { 
        path: '/roc-list', 
        name: 'roc-list',
        component: RocList,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
        // meta:{authorize:['admin','deo']},
        
    },
    { 
        path: '/dept-po-list', 
        name: 'dept-po-list',
        component: DeptPoList,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
        // meta:{authorize:['admin','deo']},
    },
    { 
        path: '/add-dept-po', 
        name: 'add-dept-po',
        component: DeptPo,
        props:true,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
        
        // meta:{authorize:['admin']},
    },
    { 
        path: '/add-oem-po', 
        name: 'add-oem-po',
        component: OemPO,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
        // meta:{authorize:['admin']},
    },
    { 
        path: '/oem-po-drafts', 
        name: 'oem-po-drafts',
        component: OemPoDrafts,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
        // meta:{authorize:['admin','deo']},
    },
    { 
        path: '/oem-po-list', 
        name: 'oem-po-list',
        component: OemPoList,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
        // meta:{authorize:['admin','deo']},
    },
    { 
        path: '/oem-po-cancelled', 
        name: 'oem-po-cancelled',
        component: OemPoCancelled,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
        // meta:{authorize:['admin','deo']},
    },
    { 
        path: '/oem-po-pdf', 
        name: 'oem-po-pdf',
        component: OemPoDoc,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },  
        // meta:{authorize:['admin','deo']},   
    },
    { 
        path: '/trialPoPdf', 
        name: 'TrialOemPdf',
        component: TrialOemPdf,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
        // meta:{authorize:['admin','deo']},    
    },
    { 
        path: '/manufacturer-list', 
        name: 'manufacturer-list',
        component: ManufacturerList,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
        // meta:{authorize:['admin','deo']},
    },
    { 
        path: '/terms-and-conditions', 
        name: 'terms-and-conditions',
        component: TermsAndConditions,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
        // meta:{authorize:['admin','deo']},
    },
    { 
        path: '/categories', 
        name: 'categories',
        component: Categories,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/store-details', 
        name: 'store-details',
        component: Store,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/invoice-details', 
        name: 'invoice-details',
        component: InvoiceDetails,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/new-invoice', 
        name: 'new-invoice',
        component: NewInvoice,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/items', 
        name: 'items',
        component: Store,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/delivery-challan', 
        name: 'delivery-challan',
        component: DeliveryChallan,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/delivery-challan-bulk', 
        name: 'delivery-challan-bulk',
        component: DeliveryChallanBulk,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },    
    { 
        path: '/cancelled-delivery-challan', 
        name: 'cancelled-delivery-challan',
        component: CancelledDC,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/add-new-bill', 
        name: 'add-new-bill',
        component: AddBill,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/bill-list-draft', 
        name: 'bill-list-draft',
        component: BillListDraft,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/bill-list', 
        name: 'bill-lis',
        component: BillList,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/bill-list-cancelled', 
        name: 'bill-list-cancelled',
        component: BillListCancelled,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    
    { 
        path: '/bill-pdf', 
        name: 'bill-pdf',
        component: BillPdf,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/dc-pdf', 
        name: 'dc-pdf',
        component: DcDoc,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },  
        // meta:{authorize:['admin','deo']},   
    },
    { 
        path: '/inventory', 
        name: 'inventory',
        component: Inventory,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/stock-arrival', 
        name: 'stock-arrival',
        component: StockArrival,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/mis-reports', 
        name: 'mis-reports',
        component: MisReport,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/equipment-installation', 
        name: 'equipment-installation',
        component: EquipmentInstallation,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/equipment-cmc-details/:id', 
        name: 'equipment-cmc-details',
        component: EquipmentCmcDetails,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/new-servicing-bill', 
        name: 'new-servicing-bill',
        component: NewServicingBill,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/servicing-bills', 
        name: 'servicing-bills',
        component: ServicingBills,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { 
        path: '/cmc-bill-doc/:id', 
        name: 'cmc-bill-doc',
        component: CmcBillDoc,
        beforeEnter:(to, from, next)=>{
            guard(to, from, next)
        },
    },
    { path: '/logout', 
        name: 'logout',
        component: Logout,
    },
    { path: '/pincode', 
        name: 'pincode',
        component: Pincode,
    },
    // { path: '/sidebar', 
    //     name: 'sidebar',
    //     component: SideBar,
    // },
    { path: '/sidebar-two', 
        name: 'sidebar-two',
        component: SideBarTwo,
    },
    // { path: '/sidebar-three', 
    //     name: 'sidebar-three',
    //     component: SideBarThree,
    // },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
  })


export default router;