<template>
  
<Navbar/>
    <SideBar/>

  <div class="main-content">

    <div class="progress-spinner" v-if="spinnerLoadingStatus">
        <ProgressSpinner></ProgressSpinner>
    </div>

    <div class="flex justify-content-between flex-wrap mt-2">
        <div class="flex align-items-center justify-content-center ml-4">
            <label class=" ml-2 text-xl font-medium">New CMC Bill</label>
        </div>
        <div class="flex align-items-center justify-content-center mr-3">
            <router-link to="/servicing-bills" rel="noopener">
                <Button label="CMC Bills"  icon="pi pi-list" severity="secondary" text raised />
            </router-link>
        </div>
    </div>

    <div class="grid">

        <div class="sm:col-12 md:col-4 lg:col-4">
          <div class="flex ">
            <div class="flex-initial flex justify-content-center w-full ml-3 "> 
                <div class="surface-card shadow-2 p-3 border-round-xl  w-full mt-4 mr-2 ml-3">
                    <div class="field mx-2">
                        <label class="my-2 text-xl font-medium">Installation Details</label><br>
                        <label class="text-lg mt-3">Installed Equipments:</label>
                        <Dropdown filter v-model="selectedEquipment" :options="installedEquips" optionLabel="item" class="mt-2 w-full" placeholder="Select an equipment" @change="fetchEquipmentDetails" showClear />
                        <label class="text-sm text-500 mt-2">Select an installed equipment</label>
                    </div>
                    <div class="field mx-2">
                        <label class="text-lg mt-4 ">Delivered Locations:</label>
                        <label class="text-sm text-500 ml-1">Locations where equipment has been installed</label>
                        <DataTable v-model:selection="selectedInstallationLocations" v-model:filters="filters" class="mt-2" :value="deliveredLocations" size="small" :loading="tableLoading" stripedRows dataKey="cmcId" paginator :rows="50" :rowsPerPageOptions="[10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" scrollable scrollHeight="400px;" @row-select="addServiceToBill" @row-unselect="removeServiceFromBill" @row-select-all="addAllServicesToBill" @row-unselect-all="removeAllServicesFromBill">

                            <template #empty>
                                <div class="flex ">
                                    <div class="flex-initial flex justify-content-center w-full"> 
                                        <label class="font-medium" >No data</label>
                                    </div>
                                </div>
                            </template>

                            <template #header>
                                <div class="flex flex-wrap align-items-center justify-content-end gap-2">
                                    
                                    <div class="flex align-items-center justify-content-center ">
                                        <InputText v-model="filters['global'].value" size="small" placeholder="Keyword Search" />
                                    </div>    
                                </div>
                            </template>

                            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                            <Column field="location.name" header="Location" style="width:8rem"></Column>
                            <Column field="quantity" header="Quantity"></Column>
                            <Column field="srPartNo" header="Pt/Sr No." style="width:4rem">
                            </Column>
                            <Column field="model" header="Model"></Column>
                            <Column field="installDate" header="Installed On"></Column>
                            <!-- <Column header="Representative"></Column> -->
                        </DataTable>
                    </div>

                </div>
            </div>
          </div>
        </div>

        <div class="sm:col-12 md:col-8 lg:col-8">
          <div class="flex ">
            <div class="flex-initial flex justify-content-center w-full ml-3"> 
                <div class="surface-card shadow-2 p-3 border-round-xl  w-full mt-4 mr-2 ml-3">

                    <div class="flex justify-content-between flex-wrap mx-3 mt-1">
                        <div class="flex align-items-center justify-content-center ">
                            <div class="field">
                                <label class=" text-xl font-medium">Bill details</label>
                            </div>      
                        </div>
                        <div class="flex align-items-center justify-content-center ">
                            <div class="field">
                                <Button label="Create bill" icon="pi pi-plus" severity="success" size="small" raised @click="saveBillData"/>
                            </div>
                        </div>
                    </div>
                    
                    <Divider style="width:97%" class="mx-3 mt-0"/>

                        <div class="formgrid grid">
                            <div class="field col ml-3">
                                <label class="text-lg">Service Bill No.:<span class="text-red-500">*</span></label>
                                <InputText v-model="serviceBillNo" class="w-full" placeholder="Enter the bill no." />
                            </div>
                            <div class="field col ml-3">
                                <label class="text-lg">PO No.:<span class="text-red-500">*</span></label>
                                <InputText v-model="deptPoNo" class="w-full" disabled placeholder="Department Order No." />
                            </div>
                            <div class="field col mr-3 ml-3">
                                <label class="text-lg" >PO Date:<span class="text-red-500">*</span></label>
                                <InputText v-model="deptPoDate" class="w-full" disabled placeholder="Department Order Date" />
                            </div>
                        </div>

                        <div class="formgrid grid">
                            <div class="field col ml-3">
                                <label class="text-lg">Bill Date:<span class="text-red-500">*</span> </label>
                                <Calendar v-model="billDate" class="w-full" placeholder="Select a bill date" dateFormat="dd/mm/yy"/>
                            </div>
                            <div class="field col mr-3 ml-3">
                                <label class="text-lg">Department:<span class="text-red-500">*</span></label>
                                <Dropdown class="w-full" v-model="selectedDept" :options="departments" optionValue="id" optionLabel="name" placeholder="Select department" />
                            </div>
                        </div>

                        <Divider style="width:97%" class="ml-3" />

                        <div class="formgrid grid">
                            <div class="field col ml-4 ">
                                <div class="flex justify-content-between flex-wrap mt-3 text-lg">
                                    <div class="flex align-items-center justify-content-start ">
                                        <label >Total Value:</label>
                                    </div>
                                    <div class="flex align-items-center justify-content-end mr-3">
                                        <label class="ml-2">{{formatCurrency(billTotalValue)}}</label>
                                    </div>
                                </div>
                                <div class="flex justify-content-between flex-wrap mt-3 text-lg">
                                    <div class="flex align-items-center justify-content-start ">
                                        <label >Value (Rounded off):</label>
                                    </div>
                                    <div class="flex align-items-center justify-content-end mr-3">
                                        <label class="ml-2">{{formatCurrency(billTotalRounded)}}</label>
                                    </div>
                                </div>
                                <div class="flex mt-5 text-500">
                                    <label style="width:33%">Amount in words:</label>
                                    <label class="ml-2">{{amountInWords}}</label>
                                </div>

                            </div>
                            <Divider layout="vertical"/>
                            <div class="field col mr-3">
                                <div class="flex justify-content-between flex-wrap">
                                    <div class="flex align-items-center justify-content-start ">
                                        <label style="width:30%">Taxable:</label>
                                    </div>
                                    <div class="flex align-items-center justify-content-end mr-3">
                                        <label class="text-right">{{formatNumber(billTaxable)}}</label>
                                    </div>
                                </div>
                                <hr class="amt-style">
                                <div class="flex justify-content-between flex-wrap mt-2">
                                    <div class="flex align-items-center justify-content-start ">
                                        <label style="width:30%">CGST(9%):</label>
                                    </div>
                                    <div class="flex align-items-center justify-content-end mr-3">
                                        <label >{{formatNumber(billCgst)}}</label>
                                    </div>                                   
                                </div>
                                <hr class="amt-style">
                                <div class="flex justify-content-between flex-wrap mt-2">
                                    <div class="flex align-items-center justify-content-start ">
                                        <label style="width:30%">SGST(9%):</label>
                                    </div>
                                    <div class="flex align-items-center justify-content-end mr-3">
                                        <label >{{formatNumber(billSgst)}}</label>
                                    </div>  
                                </div>
                                <hr class="amt-style">
                                <div class="flex justify-content-between flex-wrap mt-2">
                                    <div class="flex justify-content-start ">
                                        <label style="width:30%">Round Off:</label>
                                    </div>
                                    <div class="flex justify-content-end mr-3">
                                        <InputNumber v-model="roundOff"  locale="en-IN" minFractionDigits="2" maxFractionDigits="5" placeholder="0.00" @input="roundOffTotal($event)"/>
                                    </div>                                    
                                </div>                                
                            </div>
                        </div>

                        <Divider style="width:96%" class="ml-4" />

                    <div class="field mx-4 ">
                        <label class="text-lg" >Equipment servicings: <span class="text-red-500">*</span><span class="text-sm text-500">Atleast one servicing needs to be added for generating bill</span> </label>
                        <ScrollPanel style="width:100%; height:45vh" class="mb-6" >

                            <DataView :value="selectedServicings">
                                <template #empty>
                                    <div class="flex mx-3 my-3">
                                        <div class="flex-initial flex  w-full"> 
                                            <label class="font-medium text-400" >Select completed servicings to add to bill</label>
                                        </div>
                                    </div>
                                </template>

                                <template #list="slotProps">
                                    <div class="grid grid-nogutter">
                                        <div v-for="(item, index) in slotProps.items" :key="index" class="col-12">

                                                <div class="flex flex-column sm:flex-row sm:align-items-center p-4 gap-2" :class="{ 'border-top-1 surface-border': index !== 0 }">
                                                    <div class="flex">
                                                        {{index+1}}.
                                                    </div>
                                                    <div class="flex flex-wrap ml-2" >
                                                        <div class="border-round gap-2 justify-content-center py-2 px-2 bg-green-100" style="border-radius: 10px; box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)">
                                                            <i class="pi pi-map-marker " ></i>
                                                        </div>                                                        
                                                    </div>
                                                    <div class="flex flex-column md:flex-row justify-content-between md:align-items-center flex-1 ">
                                                        <div class="flex flex-row md:flex-column  align-items-start ">
                                                            <div>
                                                                <span class="font-medium text-secondary text-sm">Year: {{ item.cmcYear }} | CMC Type: {{getServicingType(item.cmcType)}}</span>
                                                                <div class="text-lg font-medium text-900 my-1">{{ item.location.name }}</div>
                                                            </div>
                                                            <div class="surface-100 p-2" style="border-radius: 10px">
                                                                <div>
                                                                    <label class="text-sm">Installation Date: <span class="font-medium">{{item.installDate}}</span></label>
                                                                    <i class="pi pi-wrench ml-2"></i>
                                                                </div>
                                                            </div>            
                                                        </div>
                                                        <div class="flex flex-row md:flex-column  align-items-start ">
                                                            <div>
                                                                <label class="col-fixed" style="width:80px"><em> Model No.:</em></label>
                                                                    <label class="font-medium">{{item.model}}</label>
                                                            </div>
                                                            <div >
                                                                <label class="col-fixed" style="width:80px"><em> Serial No.:</em></label>
                                                                    <label class="font-medium">{{item.srPartNo}}</label>
                                                            </div>
                                                            <div >
                                                                <label class="col-fixed" style="width:80px"><em> Serviced on:</em></label>
                                                                    <label class="font-medium">{{item.servicingDate}}</label>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="  md:align-items-end sm:align-items-end gap-1">
                                                            <!-- <div class="formgrid grid"> -->
                                                                <div class="field ">
                                                                    <label class="text-lg font-semibold text-900">{{ formatNumber(item.subTotal) }} + {{(item.cmcGst)+'%'}}</label>
                                                                </div>
                                                                <div class="field ">
                                                                    <label class="font-medium">{{item.quantity}} x {{formatNumber(item.cmcRate)}}</label>
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div>                                         
                                        </div>
                                    </div>
                                </template>
                            </DataView>
                        </ScrollPanel>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
  </div>

</template>

<script>
import Dropdown from 'primevue/dropdown'
import Navbar from '../header/headerNavbar.vue'
import SideBar from '../sidebar/sideBarTwo.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import DataView from 'primevue/dataview'
// import Tag from 'primevue/tag'
import ScrollPanel from 'primevue/scrollpanel'
import Divider from 'primevue/divider'
import Calendar from 'primevue/calendar'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import { createCmcBill, getDepartments, getInstalledEquipmentDetails, getInstalledEquipsServices } from '@/services/apiService'
import ProgressSpinner from 'primevue/progressspinner'
import { FilterMatchMode } from 'primevue/api'
import { numberToWord } from '../../services/converter/numberToWords';

export default {
    
    components:{
        Navbar,
        SideBar,
        Dropdown,
        DataTable,
        Column,
        DataView,
        // Tag,
        ScrollPanel,
        Divider,
        Calendar,
        Button,
        InputText,
        InputNumber,
        ProgressSpinner,
    },

    data(){
        return{
            installedEquips:[],
            selectedEquipment:null,
            spinnerLoadingStatus:false,
            billDate:null,
            selectedDept:null,
            serviceBillNo:null,
            deptPo:null,
            deptPoNo:null,
            deptPoDate:null,
            departments:[],
            deliveredLocations:[],
            selectedInstallationLocations:null,
            selectedServicings:[],
            billTotalValue : 0.00,
            billTotalRounded : 0.00,
            billTaxable : 0.00,
            billCgst : 0.00,
            billSgst : 0.00,
            roundOff : 0.00,
            gstRate:18,
            igst: 0,
            amountInWords:null,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                'location.name': { value: null, matchMode: FilterMatchMode.IN },
                srPartNo: { value: null, matchMode: FilterMatchMode.IN },          
                model: { value: null, matchMode: FilterMatchMode.IN },
            },
        }
    },

    methods:{
        fetchEquipments(){
            this.spinnerLoadingStatus = true;
            getInstalledEquipsServices().then((res)=>{
                this.installedEquips = res.data;
                this.calculateBillValue();
                this.spinnerLoadingStatus = false;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            });
        },
        fetchEquipmentDetails(){
            if(this.selectedEquipment==null){
                this.clearUi()
                this.deliveredLocations = []
                return;
            }
            this.deptPo = this.selectedEquipment.deptPo;
            this.deptPoNo = this.selectedEquipment.deptPo.po_no;
            this.deptPoDate = this.selectedEquipment.deptPoDate;
            this.spinnerLoadingStatus = true;
            getInstalledEquipmentDetails(this.selectedEquipment.deptOrderItemId).then((res)=>{
                this.deliveredLocations = res.data;
                this.spinnerLoadingStatus = false;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            })
        },
        getServicingType(type){
            if(type==3){
                return 'Annual';
            }
        },
        calculateBillValue(){
            this.billTotalValue = 0;
            this.billTotalRounded = 0;
            this.billTaxable = 0;
            this.billCgst = 0;
            this.billSgst = 0;
            this.selectedServicings.forEach(e => {
                this.billTaxable += e.subTotal;
                this.billCgst += (e.subTotal/100)*(e.cmcGst/2);
                this.billSgst += (e.subTotal/100)*(e.cmcGst/2);
            });

            this.billTotalValue = this.billTaxable + this.billCgst + this.billSgst;
            this.billTotalRounded = this.billTotalValue;
            this.amountInWords = this.fetchNumberToWords(this.billTotalRounded)
        },
        roundOffTotal(event){
            this.billTotalRounded = 0;
            this.billTotalRounded = this.billTotalValue + event.value;
            // this.amountInWords = this.fetchNumberToWords(this.billTotalRounded)
        },
        addServiceToBill(event){            
            this.selectedServicings.push(event.data);
            this.calculateBillValue();            
        },
        removeServiceFromBill(event){
            const index = this.selectedServicings.findIndex((e)=>e.cmcId ==event.data.cmcId);
            this.selectedServicings.splice(index, 1);
            this.calculateBillValue();
        },
        addAllServicesToBill(event){
            this.selectedServicings = [];
            event.data.forEach(e => {
                this.selectedServicings.push(e);
            });
            this.calculateBillValue();
        },
        removeAllServicesFromBill(){
            this.selectedServicings = [];
            this.calculateBillValue();
            this.selectedInstallationLocations = [];            
        },
        saveBillData(){
            this.selectedServicings.forEach(e => {
                e.hsn = this.$serviceHsn;
                e.locationId = e.location['id'];
            });

            if(this.serviceBillNo==null || this.deptPoNo==null || this.deptPoDate==null || this.billDate==null || this.selectedDept==null){
                return this.showErrorToast({'message':'Please fill all the required fields'});
            }

            if(this.selectedServicings.length==0){
                return this.showErrorToast({'message':'At-least 1(one) servicing needs to be selected for generating bill!'});
            }

            const data = {
                'serviceBillNo':this.serviceBillNo,
                'deptOrder':this.deptPo.id,
                'poNo':this.deptPoNo,
                'poDate':this.deptPoDate,
                'billDate':this.billDate.getFullYear()+'-'+(this.billDate.getMonth()+1)+'-'+this.billDate.getDate(),
                'department':this.selectedDept,
                'taxable':this.billTaxable,
                'cgst':this.billCgst,
                'sgst':this.billSgst,
                'roundOff':this.roundOff,
                'totalBillValue':this.billTotalValue,
                'totalRoundBillValue':this.billTotalRounded,                                  
                'billItems':this.selectedServicings,
            }

            this.spinnerLoadingStatus = true;
            createCmcBill(data).then((res)=>{
                this.showSuccessToast(res.data);
                // this.clearUi()
                // this.fetchEquipments();
                // this.spinnerLoadingStatus = false;
                this.$router.push('/servicing-bills')
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            })
            
        },
        clearUi(){
            this.serviceBillNo = null
            this.deptPo = null
            this.deptPoNo = null
            this.deptPoDate = null
            this.billDate = null
            this.selectedDept = null
            this.billTaxable = 0.0
            this.billCgst = 0.0
            this.billSgst = 0.0
            this.roundOff = 0.0
            this.billTotalValue = 0.0
            this.billTotalRounded = 0.0
            this.selectedServicings = []
            this.selectedInstallationLocations = [];  
        },
        formatCurrency(value) {
                return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' }, {maximumFractionDigits:4, minimumFractionDigits:2});
        },
        formatNumber(value) {
                return value.toLocaleString('en-IN', {maximumFractionDigits:4, minimumFractionDigits:2});
        },
        fetchNumberToWords(n){
            return numberToWord(n)            
        },
        showErrorToast(error){
              this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 3000 });
        },
    },

    mounted(){
        
        this.fetchEquipments();
        getDepartments().then((res)=>{            
            this.departments = res.data;
        }).catch((error)=>{
            this.showErrorToast(error.response.data);
        })
    }

}
</script>

<style>

hr.amt-style{
    border-top: 1px dashed #d5d8dc;
    margin-right: 1rem;
}

.p-scrollpanel-wrapper{
    border: 1px solid #e3e3e3;
}

</style>