<template>
  <Navbar/>
  <SideBar/>

<div class="card main-content">
    <div class="progress-spinner" v-if="spinnerLoadingStatus">
            <ProgressSpinner></ProgressSpinner>
        </div>
    <div class="surface-ground">
        <Card>
            <template #title>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex align-items-center justify-content-center">
                        Invoice Details
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <Button label="Save Invoice" icon="pi pi-save" severity="success" size="small" @click="saveInvoice"/>
                    </div>
                </div>
            </template>
            <template #content>
                <div class="flex justify-content-end pb-2 px-5 ">

                    <table>
                        <tr>
                            <th class="pr-2" align ="right">Discount:</th>
                            <td>{{invoiceDiscount}}</td>
                        </tr>
                        <tr>
                            <th class="pr-2" align ="right">GST:</th>
                            <td>{{invoiceGst}}</td>
                        </tr>
                        <tr>
                            <th class="pr-2" align ="right">Total Value:</th>
                            <td>{{invoiceValue}}</td>
                        </tr>
                    </table>
                   
                </div>    
                <div class="formgrid grid">
                    <div class="field col">
                        <label >Invoice No.:</label>
                        <InputText v-model="invoiceNo" type="text" class="w-full" />
                    </div>
                    <div class="field col">
                        <label >Invoice Date:</label>
                        <Calendar v-model="invoiceDate" class="w-full pr-3"/>
                    </div>
                    
                    <!-- <div class="field col">
                        <label >Discount:</label>
                        <InputNumber v-model="invoiceDiscount" class="w-full" readonly locale="en-IN" mode="currency" currency="INR" currencyDisplay="code" :minFractionDigits="2" :maxFractionDigits="4"/>
                    </div> -->
                    <!-- <div class="field col">
                        <label >GST:</label>
                        <InputNumber v-model="invoiceGst" class="w-full" readonly locale="en-IN" mode="currency" currency="INR" currencyDisplay="code" :minFractionDigits="2" :maxFractionDigits="4"/>
                    </div> -->
                    <!-- <div class="field col">
                        <label >Invoice Value:</label>
                        <InputNumber v-model="invoiceValue" class="w-full" readonly locale="en-IN" mode="currency" currency="INR" currencyDisplay="code" :minFractionDigits="2" :maxFractionDigits="4"/>
                    </div> -->
                    <div class="field col">
                    </div>
                    <div class="field col">
                    </div>
                    <div class="field col">
                    </div>
                    
                    <div class="field col">
                        <label >OEM:</label>
                        <Dropdown :options="oems" filter optionValue="id" optionLabel="name" v-model="selectedOem" class="surface-overlay w-full " @change="filterOemItems"/>
                    </div>
                    <div class="field col">
                        <label >Select Item:</label>
                        <Dropdown :options="filteredItems" filter optionValue="index" optionLabel="item_name2" v-model="selectedItem" class="surface-overlay w-full " @change="addInvItem"/>
                    </div>
                </div>
                
                <hr>

                <div v-if="invoiceItems.length>0" class="flex justify-content-between flex-wrap pb-2">
                    <div class="flex align-items-center justify-content-center">
                        <h3> Invoice Items</h3>
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <Button icon="pi pi-trash" severity="danger" size="small" @click="removeItem" v-tooltip.left="'Deletes last item'"/>
                    </div>
                </div>

                <!-- <InvoiceItem v-for="(item, index) in invoiceItems" :key="item.id" :id="index" :index="index" :item="item" @bind-item="bindData" @delete="removeItem(index)"/> -->
                <InvoiceItem v-for="(item, index) in invoiceItems" :key="item.id" :id="index" :index="index" :item="item" @bind-item="bindData" />
        
            </template>
        </Card>
    </div>
</div>

<Dialog :visible="itemDialogVisibility" modal :closable="false" :header="dialogHeader" :style="{ width: '45rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">    

    <div class="formgrid grid">
        <div class="field col">
            <label>Name:</label>
            <InputText v-model="itemName" type="text" class="w-full" />
        </div>
        <div class="field col">
            <label>Arrived Qty:</label>
            <InputText v-model="availQty" type="text" class="w-full" readonly/>
        </div>
        <div class="field col">
            <label >HSN:</label>
            <Dropdown :options="hsnArr" filter editable optionValue="hsn" optionLabel="hsn" v-model="itemHsn" class="surface-overlay w-full "/>
            <!-- <InputNumber v-model="itemHsn" type="text" class="w-full" :useGrouping="false"/> -->
        </div>


    </div>       
    <div class="formgrid grid">
        <div class="field col" v-if="partNoStatus">
            <label >Part/Serial No.:</label>
            <InputText v-model="itemPart" type="text" class="w-full" />
        </div>
        <div class="field col" v-if="partNoStatus">
            <label >Model No.:</label>
            <InputText v-model="itemModel" type="text" class="w-full" />
        </div>
        <div class="field col" v-if="!partNoStatus">
            <label >Batch No.:</label>
            <InputText v-model="itemBatch" type="text" class="w-full" />
        </div>
    </div>    
    <div class="formgrid grid">
        <div class="field col" v-if="!partNoStatus">
            <label >Mfg Date:</label>
            <Calendar v-model="itemMfg" class="w-full" />
        </div>
        <div class="field col" v-if="!partNoStatus">
            <label >Exp Date:</label>
            <Calendar v-model="itemExp" class="w-full" />
        </div>
        <div class="field col">
            <label >Packing:</label>
            <InputText v-model="itemPacking" type="text" class="w-full" />
        </div>
    </div>   
    <div class="formgrid grid">
        <!-- <div class="field col">
            <label >Package Qty:</label>
            <InputNumber v-model="itemNop" type="text" class="w-full" />
        </div> -->
        <div class="field col">
            <label >Package Qty:</label>
            <InputNumber v-model="itemPackQty" type="text" class="w-full" @input="updateQty"/>
        </div>
        <!-- <div class="field col">
            <label >Total Qty:</label>
            <InputNumber v-model="itemTotalQty" type="text" class="w-full" readonly/>
        </div> -->
    </div>  
    <div class="formgrid grid">
        <div class="field col">
            <label >Rate:</label>
            <InputNumber v-model="itemRate" type="text" class="w-full" @input="updateAmount" :minFractionDigits="2" :maxFractionDigits="4"/>
        </div>
        <div class="field col">
            <label >Amount:</label>
            <InputNumber v-model="itemAmt" type="text" class="w-full" readonly :minFractionDigits="2" :maxFractionDigits="4"/>
        </div>
        
    </div>   
    <div class="formgrid grid">
        <div class="field col">
            <label >Gst(%):</label>
            <InputNumber v-model="itemGstPerc" type="text" class="w-full" @input="updateGst" :minFractionDigits="2" :maxFractionDigits="4"/>
        </div>
        <div class="field col">
            <label >Gst:</label>
            <InputNumber v-model="itemGst" type="text" class="w-full" readonly :minFractionDigits="2" :maxFractionDigits="4"/>
        </div>
        <div class="field col">
            <label >Disc(%) Ex-GST:</label>
            <InputNumber v-model="itemDiscPerc" type="text" class="w-full" @input="updateTotal" :minFractionDigits="2" :maxFractionDigits="4"/>
        </div>
        <div class="field col">
            <label >Disc (Ex-GST):</label>
            <InputNumber v-model="itemDisc" type="text" class="w-full" readonly :minFractionDigits="2" :maxFractionDigits="4"/>
        </div>
    </div>      
    <div class="formgrid grid">
        <!-- <div class="field col">
            <label >Before Discount:</label>
            <InputNumber v-model="subTotalBeDsc" type="text" class="w-full" readonly :minFractionDigits="2" :maxFractionDigits="4"/>
        </div> -->
        <div class="field col">
            <label >After Discount:</label>
            <InputNumber v-model="subTotal" type="text" class="w-full" readonly :minFractionDigits="2" :maxFractionDigits="4"/>
        </div>
    </div>        

    <template #footer>   
        <Button class="my-1" size="small" label="Add" severity="success" @click="addInvItem"></Button>
        <Button class="my-1" size="small" label="Close" severity="danger" @click="hideItemDialog"></Button>
    </template>
</Dialog>
</template>

<script>
import Card from 'primevue/card'
import Navbar from '../header/headerNavbar.vue'
import InputText from 'primevue/inputtext'
import Calendar from 'primevue/calendar'
import InputNumber from 'primevue/inputnumber'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import { createInvoice, fetchHsnList, getManufacturers, getOemPoList } from '@/services/apiService'
import ProgressSpinner from 'primevue/progressspinner'
import InvoiceItem from './invoiceItem.vue'
import Dialog from 'primevue/dialog'
// import DataTable from 'primevue/datatable'
// import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
    components:{
        Card,
        Navbar,
        SideBar,
        InputText,
        Calendar,
        InputNumber,
        Button,
        Dropdown,
        ProgressSpinner,
        InvoiceItem,
        Dialog,
        //DataTable,
        //Column,
    },
    data(){
        return{
            expandedRows:[],
            // arrivedItems:[],
            invoiceNo:null,
            invoiceDate:null,
            transport:null,
            cnNo:null,
            freight:0,
            insurChg:0,
            igst:0,
            basicAmt:0,
            discAmt:0,
            totalValue:0,
            invoiceItems:[],
            oemPos:[],
            selectedOemPo:null,
            spinnerLoadingStatus:false,
            oemPoItems:null,
            selectedPoItem:[],
            disableStatus:true,
            itemDialogVisibility:false,
            dialogHeader:null,
            itemId:null,
            itemName:null,
            itemCategory:null,
            availQty:0,
            itemHsn:null,
            itemPart:null,
            itemModel:null,
            itemBatch:null,
            itemMfg:null,
            itemExp:null,
            itemPacking:null,
            itemNop:0,
            itemPackQty:0,
            itemTotalQty:0,
            itemRate:0,
            itemAmt:0,
            itemGstPerc:0,
            itemGst:0,
            itemDiscPerc:0,
            itemDisc:0,
            subTotalBeDsc:0,
            subTotal:0,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                item_name: { value: null, matchMode: FilterMatchMode.IN },
                // roc_refs:{ value: null, matchMode: FilterMatchMode.IN },
            },
            presentItem:null,
            invoiceDiscount:0,
            invoiceGst:0,
            invoiceValue:0,
            selectedItem:null,
            oems:[],
            selectedOem:null,
            filteredItems:[],
        }
    },

    methods:{
        getManufacturers(){
            this.spinnerLoadingStatus = true;
            getManufacturers().then((res)=>{
                this.oems = res.data;
                this.spinnerLoadingStatus = false;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            })
        },
        getOemPos(){
            this.spinnerLoadingStatus = true;
            getOemPoList([]).then((res)=>{
                res.data.forEach(e => {
                    e.oem_po_details.every(el => {
                        if(el.arrived.length>0){
                            this.oemPos.push(e);
                            return false;
                        }
                        return true;
                    });
                });
                this.selectPo();
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
            });
        },
        selectPo(){
            this.oemPoItems = [];
            let index = 0;

            this.oemPos.forEach(k => {
                k.oem_po_details.forEach(e => {

                e.arrived.forEach(el => {
                    if(el.invoiceStatus==0){
                        this.oemPoItems.push({
                            ...e,
                            index:index++,
                            item_name2:e.item_name+' ('+el.quantity+'/'+e.quantity+') - '+e.location[0].location,
                            arrived:el,
                            manufacturer:k.manufacturer,
                        });
                    }
                });
            });
            });
            this.filteredItems = this.oemPoItems;            

            this.spinnerLoadingStatus = false;
            this.disableStatus = false;
        },
        filterOemItems(){
            
            this.filteredItems = [];
            this.oemPoItems.forEach(e => {
                if(e.manufacturer.id==this.selectedOem){
                    this.filteredItems.push(e);
                }
            });            
        },
        getHsnList(){
            fetchHsnList().then((res)=>{
                this.hsnArr = res.data;
            })
        },
        updateQty(event){
            
            this.itemAmt = this.itemRate * event.value;
            this.itemGst = (this.itemAmt/100)*this.itemGstPerc;
            // this.subTotalBeDsc = this.itemAmt + this.itemGst;
            this.subTotal = (this.itemAmt - this.itemDisc) + this.itemGst;
        },
        updateAmount(event){
            
            this.itemAmt = event.value * this.itemPackQty;
            this.itemGst = (this.itemAmt/100)*this.itemGstPerc;
        },
        updateGst(event){
            this.itemGst = (this.itemAmt/100)*event.value;
            // this.subTotalBeDsc = this.subTotal = (this.itemAmt - this.itemDisc) + this.itemGst;
        },
        updateTotal(event){  
            this.itemAmt = this.itemRate * this.itemPackQty;
      
            this.itemDisc = (this.itemAmt/100)*event.value;
            this.itemAmt -= this.itemDisc;
            this.itemGst = (this.itemAmt/100)*this.itemGstPerc;
            // this.subTotalBeDsc = this.itemAmt + this.itemGst;
            this.subTotal = this.itemAmt + this.itemGst;
        },
        addInvItem(){

            const item = this.oemPoItems.find((e)=>e.index==this.selectedItem);

            this.invoiceItems.push({
                id: item.index,
                itemId:item.id,
                stockArrId:item.arrived.stockArrId,
                name: item.item_name,
                itemHsn: null,
                packing: item.packing,
                category:item.category,
                itemPackQty:0,
                gstPerc: item.gst,
                gstAmt: 0,
                discPerc: 0,
                discAmt: 0,
                partNoStatus: null,
                partNo: null,
                modelNo: null,
                batchNo: null,
                rate: item.rate,
                amount: 0,
                arrivedQty: item.arrived.quantity,
                mfgDate: null,
                expDate: null,
                subTotal: 0,
            });

        },
        removeItem(){

            let item2 = this.invoiceItems.pop();
            this.invoiceDiscount -= item2.discAmt;
            this.invoiceGst -= item2.gstAmt;
            this.invoiceValue -= item2.subTotal;
            
        },
        bindData(itemData, index){

            let item = null;
            for (let i = 0; i < this.invoiceItems.length; i++) {
                if(i==index){
                    item = this.invoiceItems[i];
                }                
            }
            
                item.name = itemData.invItemName,
                item.packing = itemData.invItemPckg,
                item.itemHsn = itemData.invItemHsn,
                item.arrivedQty = itemData.invItemAvailQty,
                item.itemPackQty = itemData.invItemPckQty,
                item.gstPerc = itemData.invItemGstPerc,
                item.gstAmt = Math.round(itemData.invItemGst * 10000)/10000,
                item.discPerc = itemData.invItemDiscPerc,
                item.discAmt = itemData.invItemDisc,
                item.partNo = itemData.invItemPrtNo,
                item.modelNo = itemData.invItemModelNo,
                item.batchNo = itemData.invItemBatchNo,
                item.rate = itemData.invItemRate
                item.amount = itemData.invItemAmt,
                item.mfgDate = itemData.invItemMfg,
                item.expDate = itemData.invItemExp,
                item.subTotal = Math.round(itemData.itemSubTotal * 10000)/10000,

            this.invoiceDiscount = 0;
            this.invoiceGst = 0;
            this.invoiceValue = 0;
            this.invoiceItems.forEach(e => {
                this.invoiceDiscount += e.discAmt;
                this.invoiceDiscount = Math.round(this.invoiceDiscount * 10000)/10000;
                this.invoiceGst += e.gstAmt;
                this.invoiceGst = Math.round(this.invoiceGst * 10000)/10000;
                this.invoiceValue += e.subTotal;
                this.invoiceValue = Math.round(this.invoiceValue * 10000)/10000;
            });
           
        },
        saveInvoice(){           

            if(this.invoiceNo==null || this.invoiceDate==null || this.selectedOem==null){
                return this.showErrorToast({'message':'Invoice no/ Invoice date / OEM selection is required'});
            }

            // * * verifying if quantity in invoice is excess against arrived quantity * *

            // this.invoiceItems.forEach((e, k) => {
            //     let availableQty = e.arrivedQty;
            //     let qty = e.itemPackQty;
            //     this.invoiceItems.forEach((l, ky) => {
            //         if(k!=ky){
            //             if(e.itemId==l.itemId && e.id==l.id){
            //                 qty += l.itemPackQty;
            //             }
            //         }
            //     });
            //     if(qty > availableQty){
            //         return this.showErrorToast({'message':'Invalid quantity for item No. '+(k+1)});
            //     }
            // });

            // * * verifying end * *

            const data = {
                // 'oemPoId':this.selectedOemPo.id,
                'oem':this.selectedOem,
                'invoiceNo':this.invoiceNo,
                'invoiceDate':this.invoiceDate.getFullYear()+'-'+(this.invoiceDate.getMonth()+1)+'-'+this.invoiceDate.getDate(),
                'invoiceItems':this.invoiceItems,
                'totalDiscount':this.invoiceDiscount,
                'totalGst':this.invoiceGst,
                'totalInvoiceValue':this.invoiceValue,
            }

            this.spinnerLoadingStatus = true;
            createInvoice(data).then((res)=>{
                this.showSuccessToast(res.data);
                this.spinnerLoadingStatus = false;
                this.$router.push('/invoice-details');
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            });
        },
        hideItemDialog(){
            this.itemDialogVisibility = false;
            this.itemName = null;
            this.availQty = 0;
            this.itemHsn = null;
            this.itemPart = null;
            this.itemModel = null;
            this.itemBatch = null;
            this.itemMfg = null;
            this.itemExp = null;
            this.itemPacking = null;
            this.itemNop = 0;
            this.itemPackQty = 0;
            // this.itemTotalQty = 0;
            this.itemRate = 0;
            this.itemAmt = 0;
            this.itemGstPerc = 0;
            this.itemGst = 0;
            this.itemDiscPerc = 0;
            this.itemDisc = 0;
            this.subTotal = 0;
        
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
        },
    },
    mounted(){
        this.getOemPos();
        this.getManufacturers();
        this.getHsnList();
    }

}
</script>

<style scoped>

.progress-spinner {
        position: fixed;
        z-index: 999;
        height: 2em;
        width: 2em;
        overflow: show;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}

</style>