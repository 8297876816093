<template>
  <Navbar/>
    <SideBar/>

<div class="card main-content">

  <div class="progress-spinner" v-if="spinnerLoadingStatus">
      <ProgressSpinner></ProgressSpinner>
  </div>

    <div class="flex justify-content-between flex-wrap">
      <div class="flex align-items-center justify-content-center">
        <label class="font-medium text-2xl  ml-4">Equipment servicing</label>
      </div>
      <div class="flex align-items-center justify-content-center">
        <router-link to="/equipment-installation" rel="noopener">
          <Button label="Back to installation" size="small" icon="pi pi-angle-left" text raised />
        </router-link>
      </div>
    </div>
      <div class="grid">
        
        <div class="sm:col-12 md:col-12 lg:col-6">
          <div class="flex ">
            <div class="flex-initial flex justify-content-center w-full"> 
              
              <div class="surface-card shadow-2 p-3 border-round-3xl  w-full mt-4 ml-3 card-height">
                
                <div class="field font-medium text-lg my-2 w-full">
                  <label >Equipment details <span class="text-sm text-400">(Details for reference)</span></label>
                </div>

                <div class="field pt-2">
                  <label >Name:</label>
                  <InputText class="w-full " v-model="itemName" disabled/>
                </div>

                <div class="field pt-2">
                    <label >Location:<span class="text-sm text-400"> (Where equipment was delivered)</span></label>
                    <InputText class="w-full " v-model="deliveredLocation" disabled/>
                </div>

                <div class="field pt-2">
                    <label >Manufacturer:</label>
                    <InputText class="w-full " v-model="manufacturer" disabled/>
                </div>

                <div class="formgrid grid pt-2">
                  <div class="field col">
                      <label >Model No.:</label>
                      <InputText class="w-full " v-model="modelNo" disabled/>
                  </div>
                  <div class="field col">
                      <label >Serial/Part No.:</label>
                      <InputText class="w-full " v-model="serialPartNo" disabled/>
                  </div>
                </div>

                <div class="formgrid grid pt-2">
                  <div class="field col">
                      <label >Installation Date:</label>
                      <InputText class="w-full " v-model="installationDate" disabled/>
                  </div>
                  <div class="field col">
                      <label >Engineer:</label>
                      <InputText class="w-full " v-model="installationEngineer" disabled/>
                  </div>
                </div>
                <div class="formgrid grid pt-2">
                  <div class="field col">
                      <label >End of Support:</label>
                      <InputText class="w-full " v-model="endOfSupportDate" disabled/>
                  </div>
                  <div class="field col">
                      <label >Quantity:</label>
                      <InputText class="w-full " v-model="installedQty" disabled/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- upcoming scheduled services card -->
        <div class="sm:col-12 md:col-12 lg:col-6">
          <div class="flex ">
            <div class="flex-initial flex justify-content-center w-full"> 

                <div class="surface-card shadow-2 p-3 border-round-3xl  w-full mt-4 ml-2 card-height">
                  <div class="flex justify-content-between font-medium text-lg my-2 w-full">
                    <div class="flex align-items-center ml-3">
                      <label >Scheduled servicing</label>

                    </div>
                    <div class="flex align-items-center mr-1">
                      <Button label="Add Servicing" size="small" text raised @click="confirmCompleteServicing(null)"/>
                    </div>
                  </div>

                  <div v-if="serviceSchedule.length == 0">
                    <label class="text-base font-medium">No cmc available as per ROC</label>
                  </div>


                  <div v-if="annualService.length > 0" class="flex justify-content-between font-medium text-lg w-full">
                    <div class="flex align-items-center">
                      <label  class="text-base font-medium ml-4 mt-2">
                        Annual Servicing
                      </label>
                    </div>
                    <div class="flex align-items-center mr-5">
                      <label class="text-base font-medium mr-4">Due In</label>
                    </div>
                  </div>

                          
                      <ScrollPanel v-if="annualService.length > 0" style="width:95%;  height:35%" class="mb-2 mx-3" :pt="{
                        wrapper: {
                            style: { 'border-right': '10px solid bg-gray-300' }
                        },
                        bary:'bg-teal-500'
                      }">
                        
                        <div v-if="annualService.length > 0" class="mt-2">
                          <div v-for="(item, index) in annualService" :key="index">
                            <div class="flex justify-content-between pt-2">
                                <div class="flex align-items-center ">
                                    <div class="field ml-3">
                                          <div class="text-base font-medium">
                                            <label >  <span class="text-500"> {{item.serviceType}} </span>| <span class="text-blue-500">Year:</span>  {{item.cmcYear}} | <span class="text-yellow-400">Servicing: </span>{{item.cmcQtr}}th</label>
                                          </div>
                                        <div class="text-sm font-medium text-gray-400"> <label class="text-400">Date: </label> <span class="text-green-500" > {{item.cmcDueDate}}</span> </div>
                                    </div>                      
                                </div>
                                <div class="flex align-items-center mr-4">
                                    <div v-if="index == 0" class="text-base">
                                      <Button icon="pi pi-check-circle" severity="success" text raised   v-tooltip.top="'Complete Servicing'" @click="confirmCompleteServicing(item)"/>
                                      <Button icon="pi pi-times-circle" severity="danger" text raised class="mx-2" v-tooltip.top="'Cancel Servicing'" @click="confirmCancelServicing(item)"/>
                                    </div>
                                    <Tag :severity="getSeverity(index)">
                                      <span class="text-base">
                                        <label >{{item.dueDays}} days</label>
                                      </span>
                                    </Tag>
                                    
                                </div>
                            </div>
                            <hr v-if="index < annualService.length - 1">
                          </div>
                        </div>
                      </ScrollPanel>
                 

                      <div class="pt-1">
                        <Divider />
                      </div>
<!-- 
                    <div class="mt-3">
                      <label v-if="primitiveService.length > 0" class="text-base font-medium ml-1 mt-4">
                        Primitive Servicing
                      </label>
                    </div> -->

                    <div v-if="primitiveService.length > 0" class="flex justify-content-between font-medium text-lg w-full">
                      <div class="flex align-items-center">
                        <label  class="text-base font-medium ml-4 ">
                          Primitive Servicing
                        </label>
                      </div>
                      <div class="flex align-items-center mr-5">
                        <label class="text-base font-medium mr-4">Due In</label>
                      </div>
                    </div>


                      <ScrollPanel v-if="primitiveService.length > 0" style="width:95%;  height:35%" class="mb-4 mx-3" :pt="{
                        bary:'bg-teal-500'
                      }">
                        
                        <div v-if="primitiveService.length > 0" class="mt-2">
                          <div v-for="(item, index) in primitiveService" :key="index">
                            <div class="flex justify-content-between pt-2">
                                <div class="flex align-items-center ">
                                    <div class="field ml-3">
                                          <div class="text-base font-medium">
                                            <label > <span class="text-500"> {{item.serviceType}} </span> | <span class="text-blue-500">Year:</span>  {{item.cmcYear}} | <span class="text-yellow-400">Servicing: </span>{{item.cmcQtr}}<span v-if="item.cmcQtr==1">st</span><span v-if="item.cmcQtr==2">nd</span><span v-if="item.cmcQtr==3">rd</span> 
                                            </label>
                                          </div>
                                        <div class="text-sm font-medium text-gray-400"> <label class="text-400">Date: </label> <span class="text-green-500" > {{item.cmcDueDate}}</span> </div>
                                    </div>                      
                                </div>
                                <div class="flex align-items-center mr-4">
                                    <div v-if="index == 0" class="text-base">
                                      <Button icon="pi pi-check-circle" severity="success" text raised   v-tooltip.top="'Complete Servicing'" @click="confirmCompleteServicing(item)"/>
                                      <Button icon="pi pi-times-circle" severity="danger" text raised class="mx-2" v-tooltip.top="'Cancel Servicing'" @click="confirmCancelServicing(item)"/>
                                    </div>
                                    <Tag :severity="getSeverity(index)">
                                      <span class="text-base">
                                        <label >{{item.dueDays}} days</label>
                                      </span>
                                    </Tag>
                                    
                                </div>
                            </div>
                            <hr v-if="index < primitiveService.length - 1">
                          </div>
                        </div>
                      </ScrollPanel>
                  

                </div>                
            </div>
          </div>
        </div>
        <!-- scheduled service crad end -->

      </div>

      <div class="grid">        
        <div class="sm:col-12 md:col-12 lg:col-12">
          <div class="flex ">
            <div class="flex-initial flex justify-content-center w-full"> 
                <div class="surface-card shadow-2 p-3 border-round-3xl  w-full mt-4 ml-2">
                  <div class="field font-medium text-lg my-2 ml-2 w-full">
                    <label >Previous servicing history</label>
                  </div>

                  <div class="field col">
                    <DataTable :value="servicingHistory" showGridlines size="small" :loading="table1Loading" dataKey="id" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" scrollable scrollHeight="200px">

                      <template #empty>
                        <div class="flex ">
                          <div class="flex align-items-center justify-content-center w-full"> 
                            <label class="text-lg">
                              No data
                            </label>
                          </div>
                        </div>
                      </template>

                      <Column field="serviceType" header="Service Type" ></Column>
                      <Column field="date" header="Servicing date" ></Column>
                      <Column field="year" header="Year" >
                        <template #body="slotProps">
                          {{ slotProps.data.year!=null ? slotProps.data.year+getTrailLabel(slotProps.data.year) : 'NA'}}
                        </template>
                      </Column>
                      <Column field="qtr" header="Quarter" >
                        <template #body="slotProps">
                          {{ slotProps.data.qtr!=null ? slotProps.data.qtr+getTrailLabel(slotProps.data.qtr) : 'NA'}}
                        </template>
                      </Column>
                      <Column field="dapartment" header="Department" ></Column>
                      <Column field="engineer" header="Engineer" ></Column>
                      <Column header="Status" >
                        <template #body>
                          <Tag value="Completed" severity="success"></Tag>
                        </template>
                      </Column>
                    </DataTable>
                  </div>

                </div>
            </div>
          </div>
        </div>

        <div class="sm:col-12 md:col-12 lg:col-12">
          <div class="flex ">
            <div class="flex-initial flex justify-content-center w-full"> 
                <div class="surface-card shadow-2 p-3 border-round-3xl  w-full mt-4 ml-2">
                  <div class="field font-medium text-lg my-2 ml-2 w-full">
                    <label >Cancelled servicing</label>
                  </div>

                  <div class="field col">
                    <DataTable :value="cancelledServicing" showGridlines :loading="table2Loading" size="small" dataKey="id" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" scrollable scrollHeight="200px">

                      <template #empty>
                        <div class="flex ">
                          <div class="flex align-items-center justify-content-center w-full"> 
                            <label class="text-lg">
                              No data
                            </label>
                          </div>
                        </div>
                      </template>

                      <Column field="serviceType" header="Service Type" ></Column>
                      <Column field="year" header="Year" >
                        <template #body="slotProps">
                          {{slotProps.data.year}}{{getTrailLabel(slotProps.data.year)}}
                        </template>
                      </Column>
                      <Column field="qtr" header="Quarter" >
                        <template #body="slotProps">
                          {{slotProps.data.qtr}}{{getTrailLabel(slotProps.data.qtr)}}
                        </template>
                      </Column>
                      <Column field="remarks" header="Remarks"></Column>
                      <Column header="Status" >
                        <template #body>
                          <Tag value="Cancelled" severity="danger"></Tag>
                        </template>
                      </Column>
                    </DataTable>
                  </div>

                </div>
            </div>
          </div>
        </div>

        <Dialog :visible="confirmDialogVisibility" :modal="true" :style="{ width: auto }" :closable="false" >

                    <div class="flex justify-content-between flex-wrap">
                        <div class="flex align-items-center justify-content-center text-xl font-semibold">
                            <label>{{confirmDialogHeader}}</label>
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <!-- <label> Year: {{1}} |Servicing: {{2}}</label> -->
                            <div class="field grid">
                                <label class="col-fixed" >Year: {{dialogServicingYear}} | Quarter: {{dialogServicingQtr}}</label>
                                <div class="col">
                                  
                                </div>
                            </div>
                        </div>                        
                    
                    </div>
                    <hr>              

                  <div class="field font-medium text-lg my-2 w-full">
                    <label >Servicing details</label> <span class="text-sm text-400">Options marked as <span class="text-red-500">*</span> are mandatory</span>
                  </div>

                  <div class="field">
                    <label class="font-medium">Centre Name:<span class="text-red-500">*</span></label>
                    <InputText class="w-full " v-model="cmcCentre" placeholder="Health facility where equipment servicing was performed"/>
                  </div>

                  <div class="formgrid grid">
                    <div class="field col">
                      <label class="font-medium">Contact No.:</label>
                      <InputText class="w-full " v-model="centreContact"/>
                    </div>

                    <div class="field col">
                      <label class="font-medium">Department:<span class="text-red-500">*</span></label>
                      <InputText class="w-full " v-model="cmcDept"/>
                    </div>
                  </div>

                    <div class="formgrid grid">
                      <div class="field col">
                        <label class="font-medium">User:<span class="text-red-500">*</span></label>
                        <InputText class="w-full " v-model="cmcUser"/>
                      </div>

                      <div class="field col">
                        <label class="font-medium" >Designation:<span class="text-red-500">*</span></label>
                        <InputText class="w-full " v-model="userDesignation" placeholder="Designation of user"/>
                      </div>
                    </div>
                    

                    <div class="formgrid grid">
                      <div class="field col w-full">
                        <label class="font-medium">Service type:<span class="text-red-500">*</span></label>
                        <SelectButton v-model="serviceType" optionLabel="name" :options="serviceTypeOptions" aria-labelledby="basic" />
            
                      </div>
                      <div class="field col">

                          <label class="font-medium">Servicing duration:</label>
                          <Calendar v-model="cmcDurationDate" selectionMode="range" class="w-full " placeholder="Start date - End date" />
                      </div>
                      <div class="field col">

                          <label class="font-medium">Engineer:<span class="text-red-500">*</span></label>
                          <Dropdown editable class="w-full" v-model="servicingEngineer" :options="engineers" optionValue="id" optionLabel="name" placeholder="Select engineer"/>
                      </div>
                    </div>

                    <div class="field col">
                      <label class="font-medium">Action taken:<span class="text-red-500">*</span></label>
                    <Textarea v-model="cmcAction" rows="4" class="w-full " placeholder="Enter servicing details here..." />
                    </div>

        <template #footer>
          <div class="flex justify-content-end flex-wrap">
              <Button class="my-1 mr-2 py-2" label="Save" severity="success" text raised @click="saveCmc"/>
              <Button class="flex align-items-center justify-content-center my-1" size="small" label="No" text raised severity="secondary" @click="confirmDialogVisibility=false"></Button>
          </div>
        </template>

        </Dialog>

        <Dialog :visible="cancelDialogVisibility" :header="confirmDialogHeader" :modal="true" :style="{ width: auto }" :closable="false" >
          <div class="flex">
              <div class="flex align-items-center justify-content-center pr-3"><i class="pi pi-exclamation-triangle" style="font-size: 2.5rem"></i></div>
              <div class="flex align-items-center justify-content-center ">
                  <p>Sure you want to cancel this servicing?</p>
              </div>            
          </div>
          <!-- <div class="flex">
            <div class="flex align-items-center justify-content-center w-full"> -->

              <div class="formgrid grid mt-3">
                  <div class="field col">
                    <label  class="font-medium">Service:</label>
                      <InputText v-model="toBeCancelServiceType" class="w-full" disabled/>
                  </div>
                  <div class="field col">
                      <label class="font-medium">Year:</label>
                      <InputText v-model="toBeCancelServiceYear" class="w-full" disabled/>
                      
                  </div>
                  <div class="field col">
                      <label class="font-medium">Quarter:</label>
                      <InputText v-model="toBeCancelServiceQtr" class="w-full" disabled/>
                  </div>
              </div>

              <div class="field mt-3">
                <label class="font-medium">Remarks:</label>
                <Textarea v-model="cancelRemarks" rows="4" class="w-full " placeholder="Type in a cancellation reason " />
            </div>
            <!-- </div>
          </div> -->
        <div class="flex justify-content-end flex-wrap">
            <Button class="my-1 mr-2 py-2" label="Yes" severity="success" text raised @click="cancelEquipServicing"/>
            <Button class="flex align-items-center justify-content-center my-1" size="small" label="No" text raised severity="secondary" @click="closeCancelDialog"></Button>
        </div>
        </Dialog>


      </div>

</div>
</template>

<script>
import InputText from 'primevue/inputtext'
import Navbar from '../header/headerNavbar.vue'
import SideBar from '../sidebar/sideBarTwo.vue'
import Textarea from 'primevue/textarea'
import SelectButton from 'primevue/selectbutton'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import { addEquipmentCmc, cancelServicing, fetchInstallationEngineers, getEquipmentCancelledCmcs, getEquipmentCmc, getEquipmentDetails } from '@/services/apiService'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Tag from 'primevue/tag'
import ProgressSpinner from 'primevue/progressspinner'
import Dialog from 'primevue/dialog'
import ScrollPanel from 'primevue/scrollpanel'
import Divider from 'primevue/divider'
// import Fieldset from 'primevue/fieldset'

export default {
  components:{
    Navbar,
      SideBar,
      InputText,
      Textarea,
      SelectButton,
      Calendar,
      Dropdown,
      Button,
      DataTable,
      Column,
      Tag,
      ProgressSpinner,
      Dialog,
      ScrollPanel,
      Divider,
      // Fieldset,
    },

    data(){
      return{
        equipment:null,
        spinnerLoadingStatus:false,
        table1Loading:false,
        table2Loading:false,
        serviceTypeOptions:[
          { name:'Breakdown', value:1},
          { name:'Primitive', value:2},
          { name:'Annual', value:3},
        ],
        durationDate:null,
        engineers:[],
        servicingHistory:[],
        cancelledServicing:[],
        serviceSchedule:[],
        itemName:null,
        deliveredLocation:null,
        manufacturer:null,
        modelNo:null, 
        serialPartNo:null,
        installationDate:null,
        installationEngineer:null,
        endOfSupportDate:null,
        installedQty:null,
        cmcCentre:null,
        centreContact:null,
        cmcDept:null,
        cmcUser:null,
        userDesignation:null,
        serviceType:null,
        cmcDurationDate:null,
        servicingEngineer:null,
        cmcAction:null,
        confirmDialogVisibility:false,
        confirmDialogHeader:'Confirm',
        cancelDialogVisibility:false,
        selectedService:null,
        primitiveService:[],
        annualService:[],
        dialogServicingYear:null,
        dialogServicingQtr:null,
        cancelRemarks:null,
        toBeCancelServiceType:null,
        toBeCancelServiceYear:null,
        toBeCancelServiceQtr:null,
        toBeCancelCmcId:null,
      }
    },
    methods:{
      fetchEquipmentDetails(){
      this.spinnerLoadingStatus = true;
        getEquipmentDetails(this.$route.params.id).then((res)=>{
        
          this.equipment = res.data;
          this.itemName = res.data.item;
          this.deliveredLocation = res.data.location;
          this.manufacturer = res.data.manufacturer.name;
          this.modelNo = res.data.modelNo;
          this.serialPartNo = res.data.partSerialNo;
          this.installationDate = res.data.installationDate;
          this.installationEngineer = res.data.engineer.name;
          this.endOfSupportDate = res.data.endOfSupport;
          this.installedQty = res.data.installedQty;
          // this.servicingHistory = res.data.cmc;
          this.serviceSchedule = res.data.scheduledCmcs;

          res.data.scheduledCmcs.forEach(e => {
              if(e.cmcQtr==4){
                this.annualService.push(e);
              }
              else{
                this.primitiveService.push(e);
              }
          });

          this.fetchPreviousServicing(this.$route.params.id);
          this.fetchCancelledServicing(this.$route.params.id);
          
          this.spinnerLoadingStatus = false;
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
          this.spinnerLoadingStatus = false;
        })
          // this.spinnerLoadingStatus = false;
      },
      fetchEngineers(){
          fetchInstallationEngineers().then((res)=>{
              this.engineers = res.data;
          }).catch((error)=>{
              this.showErrorToast(error.response.data);
          })
      },
      fetchPreviousServicing(id){
        this.table1Loading = true;
        getEquipmentCmc(id).then((res)=>{          
          this.servicingHistory = res.data[0];
          this.table1Loading = false;
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
          this.table1Loading = false;
        })
      },
      fetchCancelledServicing(id){
        this.table2Loading = true;
        getEquipmentCancelledCmcs(id).then((res)=>{
          this.cancelledServicing = res.data;          
          this.table2Loading = false;
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
          this.table2Loading = false;
        })
      },
      saveCmc(){
       
        
        if(this.cmcCentre==null || this.cmcDept==null || this.cmcUser==null || this.userDesignation==null || this.serviceType==null || this.servicingEngineer==null || this.cmcAction==null){
          return this.showErrorToast({'message':'Please fill all the required fields!'});
        }

        const data = {
          selectedService:this.selectedService,
          equipmentId:this.equipment.id,
          cmcCentre: this.cmcCentre,
          cmcCentreContact: this.centreContact,
          cmcDept: this.cmcDept,
          cmcUser: this.cmcUser,
          cmcUserDesignation: this.userDesignation,
          cmcServiceType: this.serviceType.value,
          cmcStartDate: this.cmcDurationDate!=null ? this.cmcDurationDate[0].getDate()+'-'+(this.cmcDurationDate[0].getMonth()+1)+'-'+this.cmcDurationDate[0].getFullYear() : null,
          cmcEndDate: this.cmcDurationDate!=null ? this.cmcDurationDate[1].getDate()+'-'+(this.cmcDurationDate[1].getMonth()+1)+'-'+this.cmcDurationDate[1].getFullYear() : null,
          cmcEngineer: this.servicingEngineer,
          cmcAction: this.cmcAction,
        }

        this.confirmDialogVisibility = false;
        this.spinnerLoadingStatus = true;
        addEquipmentCmc(data).then((res)=>{
          this.clearContent();
          this.showSuccessToast(res.data);
          this.fetchEquipmentDetails();
          this.spinnerLoadingStatus = false;
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
          this.spinnerLoadingStatus = false;
        })
        
      },
      clearContent(){
        this.cmcCentre = null;
        this.centreContact = null;
        this.cmcDept = null;
        this.cmcUser = null;
        this.userDesignation = null;
        this.serviceType = null;
        this.cmcDurationDate = null;
        this.servicingEngineer = null;
        this.cmcAction = null;
      },
      confirmCompleteServicing(item){
        this.cmcCentre = this.equipment.billDocLocation

        if(item){
          this.selectedService = item.id;
          this.dialogServicingYear = item.cmcYear;
          this.dialogServicingQtr = item.cmcQtr;
          if(item.cmcQtr==4){
            this.serviceType = this.serviceTypeOptions[2];
          }
          if(item.cmcQtr!=4){
            this.serviceType = this.serviceTypeOptions[1];
          }
        }
        else{
          this.selectedService = null;
          this.dialogServicingYear = null;
          this.dialogServicingQtr = null;
          this.serviceType = this.serviceTypeOptions[0];
        }
        this.confirmDialogVisibility = true;
        this.confirmDialogHeader = 'Complete servicing';
      },
      confirmCancelServicing(data){
        
        this.toBeCancelServiceType = data.serviceType;
        this.toBeCancelServiceYear = data.cmcYear;
        this.toBeCancelServiceQtr = data.cmcQtr;
        this.toBeCancelCmcId = data.id;

        this.cancelDialogVisibility = true;
        this.confirmDialogHeader = 'Cancel servicing';

      },
      cancelEquipServicing(){
        const data = {
          'remarks':this.cancelRemarks,
        }
        
        this.spinnerLoadingStatus = true;
        this.cancelDialogVisibility=false;
        cancelServicing(data, this.toBeCancelCmcId).then((res)=>{
          this.closeCancelDialog();
          this.showSuccessToast(res.data);
          this.spinnerLoadingStatus = false;
          this.fetchEquipmentDetails();
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
          this.spinnerLoadingStatus = false;
        })
        
      },
      closeCancelDialog(){
        this.cancelDialogVisibility=false;
        this.toBeCancelCmcId = null;
        this.cancelRemarks = null;
      },
      getSeverity(index){
        if(index == 0)
          return 'danger';
        if(index > 0 )
          return 'success';
      },
      getTrailLabel(value){
        if(value==1)
          return 'st';
        if(value==2)
          return 'nd';
        if(value==3)
          return 'rd';
        if(value>=4)
          return 'th';
      },
      showErrorToast(error){
          this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
      },
      showSuccessToast(data){
          this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 3000 });
      },
    },
    mounted(){
      this.fetchEngineers();   
      this.fetchEquipmentDetails();       
    }
}
</script>

<style>

hr {
  border-color: #f2f2f2;
}

.card-height {
  height: 57vh;
}

.p-scrollpanel-wrapper{
  margin-top: 10px;
  border: solid #c9c9c9 1px;
  border-radius: 10px;
}

.p-scrollpanel-bar-y{
  color: black;
}

/* .service-sub-section{
  overflow: auto;
  height: 35%;
} */


</style>