<template>
  
    <div style="background:#FFFFFF;" class="parent">
        <table ref="table1" width="100%" style="font-size:11px; background-color: #FFFFFF; margin-top:15px;" >
            <thead >
                        <tr>
                            <td colspan="12" style="text-align: center; font-size: 18px; padding: 1% 0;">
                                <label ><strong>Tax Invoice</strong></label>
                            </td>
                        </tr>

                        <tr>
                          <td rowspan="2" colspan="5" style="padding-left:1%; padding-bottom:2%;">
                              <label style="font-size:16px;"><strong>Arengh Medical Supplier</strong></label><br>
                              HAWAKHANA, TURA, South West Garo Hills, Meghalaya, 794001<br>
                              GSTIN/UIN: 17ABEFA6546N1Z1<br>
                              PAN: ABEFA6546N <br>
                              State Name: Meghalaya, Code 17
                          </td>
                          <td colspan="4" class="header-cell-padding">Invoice No. <br> <strong> {{billNo}}</strong></td>
                          <td colspan="3" class="header-cell-padding">Dated <br> <strong>{{billDate}}</strong></td>
                        </tr>
                        <tr>
                            <td colspan="4" class="header-cell-padding">Delivery Challan No. <br>
                                <strong>
                                    {{deliveryChallans}}
                                </strong>
                            </td>
                            <td colspan="3" class="header-cell-padding" style="padding-bottom:1%" >Delivery Challan Date<br> 
                            <strong>
                                {{dcDates}}
                            </strong>
                            </td>
                        </tr>
                        
                        <tr>
                            <td style="padding-left:1%; padding-bottom:3%;" colspan="5" rowspan="2"> Buyer <br> <strong> The {{buyerDesignation}}</strong>
                                <br>
                                {{buyerDept}} <br>
                                State Name: {{buyerState}}, Code: {{buyerStateCode}}
                            </td>
                            <td colspan="4" class="header-cell-padding">Supplier's Ref. <br> <strong> </strong></td>
                            <td colspan="3" class="header-cell-padding">Other References <br> <strong></strong></td>

                        </tr>
                        <tr>
                            <td colspan="4" class="header-cell-padding wrap-long-po">Buyer's Order No. <br><strong>{{deptPoNo}}</strong></td>
                            <td colspan="3" class="header-cell-padding">Dated <strong><br> {{deptPoDate}}</strong> </td>
                        </tr>

                         <tr>
                          <th  width="2%;" class="wrap-word">Sl <br> No.</th>
                          <th width="20%;" >Description of Goods</th>  
                          <th width="6%;">HSN/SAC</th>
                          <th width="3%;">GST<br>(%)</th>
                          <th width="8%;" class="wrap-word">Sr No/ <br> Batch No</th>
                          <th width="10%;">Mfg Date/ <br> Model No. </th>
                          <th width="10%;">Exp Date </th>
                          <th width="6%;">Packing</th>
                          <th width="5%;">UOM</th>
                          <th width="10%;">Quantity</th>
                          <th width="10%;">Rate</th>
                          <th width="10%;">Amount</th>
                        </tr>
                        
            </thead>
            <tbody >               
            
                    <tr v-for="(item, index) in items" :key="index" valign="top">
                        <!-- <div class="break-page"> -->
                            <td align="center" class="border-hidden" style="padding-bottom: 1%; vertical-align: top;">
                                    {{item.slNo}}                                                  
                            </td>
                        <!-- </div> -->
                        <td align="left" class="border-hidden" style="vertical-align: top;">
                            <strong>
                                {{item.name}}
                            </strong> 
                        </td>
                        <td align="center" class="border-hidden" style="vertical-align: top;">
                            {{item.hsn}}
                        </td>
                        <td align="center" class="border-hidden" style="vertical-align: top;">
                            {{item.gstRate}}
                        </td>
                        <td align="center" class="border-hidden wrap-word" style="vertical-align: top;">
                            {{item.partNo}} {{item.batchNo}}
                        </td>
                        <td align="center" class="border-hidden" style="vertical-align: top;">
                            {{item.mfgDate}} {{item.modelNo}}
                        </td>
                        <td align="center" class="border-hidden" style="vertical-align: top;">
                            {{item.expDate}}
                        </td>
                        <td align="center" class="border-hidden" style="vertical-align: top;">
                            {{item.packing}}
                        </td>
                        <td align="center" class="border-hidden" style="vertical-align: top;">
                            {{item.uom}}
                        </td>
                        <td align="center" class="border-hidden" style="vertical-align: top;">
                            <strong>
                                {{formatCurrency(item.quantity)}} <br>
                            </strong>
                        </td>
                        <td align="right" class="border-hidden" style="vertical-align: top;">
                            {{formatCurrencyFourDecimal(item.exGstRate)}}
                        </td>
                        <td align="right" class="border-hidden" style="vertical-align: top;">
                            {{formatCurrencyTwoDecimal(item.exGstSubTotal)}}
                        </td>
                    </tr>                
                        
                <tr >
                    <td>
                        <div id="additional-padding"></div>
                    </td>
                    <td>
                        <div id="additional-padding"></div>
                    </td>
                    <td>
                        <div id="additional-padding"></div>
                    </td>
                    <td>
                        <div id="additional-padding"></div>
                    </td>
                    <td>
                        <div id="additional-padding"></div>
                    </td>
                    <td>
                        <div id="additional-padding"></div>
                    </td>
                    <td>
                        <div id="additional-padding"></div>
                    </td>
                    <td>
                        <div id="additional-padding"></div>
                    </td>
                    <td>
                        <div id="additional-padding"></div>
                    </td>
                    <td>
                        <div id="additional-padding"></div>
                    </td>
                    <td>
                        <div id="additional-padding"></div>
                    </td>
                    <td>
                        <div id="additional-padding"></div>
                    </td>
                </tr>

                <tr>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td colspan="2">
                        <strong>
                            Taxable Value
                        </strong>
                    </td>
                    <td align="right">
                        <strong>
                            {{formatCurrencyTwoDecimal(taxableValue)}}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td colspan="2">
                        <strong>
                            CGST
                        </strong>
                    </td>
                    <td align="right">
                        <strong>
                            {{formatCurrencyTwoDecimal(totalCgst)}}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td colspan="2">
                        <strong>
                            SGST
                        </strong>
                    </td>
                    <td align="right">
                        <strong>
                            {{formatCurrencyTwoDecimal(totalSgst)}}
                        </strong>
                    </td>
                </tr>
                <tr v-if="roundOff!=0">
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td class="hide-head-border"></td>
                    <td colspan="2">
                        <strong>
                            Round Off
                        </strong>
                    </td>
                    <td align="right">
                        <strong>
                            {{(roundOff)}}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2">
                        <strong>
                            Total
                        </strong>
                    </td>
                    <td align="right">
                        <strong>
                            {{formatCurrencyTwoDecimal(totalSgst + totalCgst + taxableValue + roundOff)}}
                        </strong>
                    </td>
                </tr>

                <tr>
                    <td colspan="12" class="footer-padding">
                        <div class="flex justify-content-between flex-wrap">

                            <div class="flex align-items-center justify-content-center">
                                <strong>Amount (in words) :</strong> <em>{{fetchNumberToWords(totalSgst + totalCgst + taxableValue + roundOff)}}</em>
                            </div>
                            <div class="flex align-items-center justify-content-center">
                                E. & O.E
                            </div>

                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="6" rowspan="2" align="center">
                        HSN/SAC <br>
                        <!-- <label v-for=" (hsn, index) in hsnList" :key="index">{{hsn}},</label> -->
                    </td>
                    <td rowspan="2" align="center">Taxable Value</td>
                    <td colspan="2" align="center">Central Tax</td>
                    <td colspan="2" align="center">State Tax</td>
                    <td rowspan="2" align="center">Total Tax Amount</td>
                </tr>
                <tr>
                    <td align="center">Tax Rate </td>
                    <td align="center">Amount</td>
                    <td align="center">Tax Rate </td>
                    <td align="center">Amount</td>
                </tr>
                <tr v-for="(item, index) in subTotalArr" :key="index">
                    <td colspan="6" align="center">
                        {{item.hsn}}
                    </td>
                    <td align="center">
                        {{formatCurrencyTwoDecimal(item.taxableAmt)}}
                    </td>
                    <td align="center">
                        {{item.gstRate/2}} (%)
                    </td>
                    <td align="center">
                        {{formatCurrencyTwoDecimal(item.cgstAmt)}}
                    </td>
                    <td align="center">
                        {{item.gstRate/2}} (%)
                    </td>
                    <td align="center">
                        {{formatCurrencyTwoDecimal(item.sgstAmt)}}
                    </td>
                    <td align="center">
                        {{formatCurrencyTwoDecimal(item.gstAmt)}}
                    </td>
                </tr>
                <tr>
                    <td colspan="6" align="right" class="pr-2">
                        <strong>
                            Total
                        </strong>
                    </td>
                    <td align="center">
                         <strong>
                            {{formatCurrencyTwoDecimal(taxableValue)}}
                        </strong>
                    </td>
                    <td align="center">                     
                    </td>
                    <td align="center">
                        <strong>
                            {{formatCurrencyTwoDecimal(totalCgst)}}
                        </strong>
                    </td>
                    <td align="center">                        
                    </td>
                    <td align="center">
                        <strong>
                            {{formatCurrencyTwoDecimal(totalSgst)}}
                        </strong>
                    </td>
                    <td align="center">
                        <strong>
                            {{formatCurrencyTwoDecimal(totalCgst + totalSgst)}}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td colspan="6" class="footer-padding">
                        <strong>Tax Amount (in words) :</strong> <em>{{fetchNumberToWords(totalCgst + totalSgst)}}</em>
                    </td>
                    <td colspan="3" class="footer-padding">
                        <strong>
                        Company' Bank Details <br>
                        Bank Name <br>
                        A/c No. <br>
                        Branch & IFSC Code
                        </strong>
                    </td>
                    <td colspan="3" class="footer-padding">
                        <br>
                        <strong>
                        : Federal Bank <br>
                        :11900200013538	<br>
                        :FDRL0001190
                        </strong>			
                    </td>
                </tr>
                <tr>
                    <td colspan="6" class="footer-padding">
                        <u> Declaration</u> 
                        <br>
                        We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.	
                        <br>
                        <br>
                        <br>
                        <br>				
                    </td>
                    <td colspan="6" align="right" class="footer-padding">
                        <strong>
                            For Arengh Medical Supplier				
                        </strong> 
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        Authorised Signatory	
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="footer-caption-two">This is a Computer Generated Document</td>
                    <!-- <td colspan="12" class="footer-caption">This is a Computer Generated Document</td> -->
                </tr>
            </tbody>
            <tfoot class="footer-margin">

                <tr>
                    <td colspan="12" class="footer-caption">
                            <!-- This is a Computer Generated Document -->
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>


<script>
import { useBillStore } from '@/stores/billStore';
import { numberToWord } from '../../services/converter/numberToWords';

export default {

    metaInfo: {
      script: [
        { src: 'https://unpkg.com/pagedjs/dist/paged.polyfill.js', async: true, defer: true }
      ],
    },

    // props:{
    //     bill:{
    //         type:Object,
    //     },
    //   },

    data(){
        
        return{
            billNo:null,
            billDate:null,
            deptPoNo: null,
            deptPoDate: null,
            deliveryChallans:null,
            dcDates:null,
            items:[],
            slNo:0,
            filteredItems:[],
            totalCgst:0,
            totalSgst:0,
            taxableValue:0,
            roundOff:0.0,
            hsnList:[],
            subTotalArr:[],
            store:null,
        }
        
    },

    methods:{
        getBiLLData(){
              const store1 = useBillStore();

            const store = store1.data;

              this.billNo = store.inv_no;
              this.billDate = store.date;
              this.deptPoNo = store.deptPoNo;
              this.deptPoDate = store.deptPoDate;
              this.buyerDesignation = store.dept.head;
              this.buyerDept = store.dept.name;
              this.buyerState = store.dept.state;
              this.buyerStateCode = store.dept.code;
              this.totalCgst = store.cgst;
              this.totalSgst = store.sgst;
              this.taxableValue = store.exGstTotal;
              this.roundOff = store.adjstValue;
  
            // store.data.deliveryChallans.forEach((e, k) => {
            store.deliveryChallans.forEach((e, k) => {
                    if(k == 0){
                        this.deliveryChallans = e.dcNo;
                        this.dcDates = e.date;
                    }
                    if(k > 0){
                        this.deliveryChallans += ', '+e.dcNo;
                        this.dcDates += ', '+e.date;
                    }
            });

            // store.data.billItems.sort((a,b)=> a.itemId.localeCompare(b.itemId));
            store.billItems.sort((a,b)=> a.itemId.localeCompare(b.itemId));

            let itemKey = null;

            store.billItems.forEach((e, key) => {

                if(key>0){

                    let item = null;
                     item = this.subTotalArr.find((l) => l.gstRate == e.gstRate);
                
                    if(item){
                        if(!item.hsn.includes(e.hsn)){
                            item.hsn += ', '+e.hsn;
                            item.cgstAmt += (e.gst/2);
                            item.sgstAmt += (e.gst/2);
                            item.gstAmt += e.gst;
                            item.taxableAmt += e.exGstSubTotal;
                        }
                        else{
                            item.cgstAmt += (e.gst/2);
                            item.sgstAmt += (e.gst/2);
                            item.gstAmt += e.gst;
                            item.taxableAmt += e.exGstSubTotal;
                        }
                    }
                    else{
                        this.subTotalArr.push({
                            'hsn':e.hsn,
                            'gstRate':e.gstRate,
                            'cgstAmt':e.gst/2,
                            'sgstAmt':e.gst/2,
                            'gstAmt':e.gst,
                            'taxableAmt':e.exGstSubTotal,
                        });
                    }

                    // end club hsn

                    // if(e.itemId != store.data.billItems[key-1].itemId){                        
                    if(e.itemId != store.billItems[key-1].itemId){                        
                        e.slNo = ++this.slNo;
                        this.items.push(e);
                        itemKey = key;
                    }
                    else{
                        e.slNo = null;
                        e.name = null;

                        // if(e.hsn == store.data.billItems[itemKey].hsn && e.gstRate == store.data.billItems[itemKey].gstRate && e.exGstRate == store.data.billItems[itemKey].exGstRate && (e.batchNo!=null && e.batchNo==store.data.billItems[itemKey].batchNo) ){
                        if(e.hsn == store.billItems[itemKey].hsn && e.gstRate == store.billItems[itemKey].gstRate && e.exGstRate == store.billItems[itemKey].exGstRate && (e.batchNo!=null && e.batchNo==store.billItems[itemKey].batchNo) ){
                            this.items[itemKey].quantity += e.quantity;
                            this.items[itemKey].exGstSubTotal += e.exGstSubTotal;
                            e.hsn = null;
                            e.gstRate = null;
                            e.quantity = null;
                            e.exGstRate = null;
                            e.exGstSubTotal = null;
                            e.batchNo = null;
                            e.expDate = null;
                            e.mfgDate = null;
                            e.packing = null;
                            e.uom = null;
                        // }else if(e.hsn == store.data.billItems[itemKey].hsn && e.gstRate == store.data.billItems[itemKey].gstRate && e.exGstRate == store.data.billItems[itemKey].exGstRate && (e.batchNo!=null && e.batchNo!=store.data.billItems[itemKey].batchNo) ){
                        }else if(e.hsn == store.billItems[itemKey].hsn && e.gstRate == store.billItems[itemKey].gstRate && e.exGstRate == store.billItems[itemKey].exGstRate && (e.batchNo!=null && e.batchNo!=store.billItems[itemKey].batchNo) ){
                            this.items[itemKey].quantity += e.quantity;
                            this.items[itemKey].exGstSubTotal += e.exGstSubTotal;
                            e.hsn = null;
                            e.gstRate = null;
                            e.quantity = null;
                            e.exGstRate = null;
                            e.exGstSubTotal = null;
                            e.expDate = null;
                            e.mfgDate = null;
                            e.packing = null;
                            e.uom = null;
                        }//new condition added below
                        else if(e.hsn != store.billItems[itemKey].hsn && e.gstRate == store.billItems[itemKey].gstRate && e.exGstRate == store.billItems[itemKey].exGstRate && (e.batchNo!=null && e.batchNo!=store.billItems[itemKey].batchNo) ){
                            this.items[itemKey].quantity += e.quantity;
                            this.items[itemKey].exGstSubTotal += e.exGstSubTotal;
                            // e.hsn = null;
                            e.gstRate = null;
                            e.quantity = null;
                            e.exGstRate = null;
                            e.exGstSubTotal = null;
                            e.expDate = null;
                            e.mfgDate = null;
                            e.packing = null;
                            e.uom = null;
                        }//new condition added
                        else if(e.hsn != store.billItems[itemKey].hsn && e.gstRate == store.billItems[itemKey].gstRate && e.exGstRate == store.billItems[itemKey].exGstRate && (e.batchNo!=null && e.batchNo==store.billItems[itemKey].batchNo) ){
                            this.items[itemKey].quantity += e.quantity;
                            this.items[itemKey].exGstSubTotal += e.exGstSubTotal;
                            // e.hsn = null;
                            e.gstRate = null;
                            e.quantity = null;
                            e.exGstRate = null;
                            e.exGstSubTotal = null;
                            e.expDate = null;
                            e.mfgDate = null;
                            e.packing = null;
                            e.uom = null;
                        }
                        else if(e.hsn == store.billItems[itemKey].hsn && e.gstRate == store.billItems[itemKey].gstRate && e.exGstRate == store.billItems[itemKey].exGstRate && e.batchNo==null && e.partNo==null && e.modelNo!=null && e.modelNo==store.billItems[itemKey].modelNo && e.packing==store.billItems[itemKey].packing && e.uom==store.billItems[itemKey].uom){
                            this.items[itemKey].quantity += e.quantity;
                            this.items[itemKey].exGstSubTotal += e.exGstSubTotal;
                            e.hsn = null;
                            e.gstRate = null;
                            e.quantity = null;
                            e.exGstRate = null;
                            e.exGstSubTotal = null;
                            e.expDate = null;
                            e.mfgDate = null;
                            e.packing = null;
                            e.uom = null;
                            e.modelNo = null;
                        }
                        // else if(e.hsn == store.data.billItems[itemKey].hsn && e.gstRate == store.data.billItems[itemKey].gstRate && e.exGstRate == store.data.billItems[itemKey].exGstRate && e.batchNo==null && e.partNo==null && e.modelNo!=null && e.modelNo!=store.data.billItems[itemKey].modelNo && e.packing==store.data.billItems[itemKey].packing && e.uom==store.data.billItems[itemKey].uom){
                        else if(e.hsn == store.billItems[itemKey].hsn && e.gstRate == store.billItems[itemKey].gstRate && e.exGstRate == store.billItems[itemKey].exGstRate && e.batchNo==null && e.partNo==null && e.modelNo!=null && e.modelNo!=store.billItems[itemKey].modelNo && e.packing==store.billItems[itemKey].packing && e.uom==store.billItems[itemKey].uom){
                            this.items[itemKey].quantity += e.quantity;
                            this.items[itemKey].exGstSubTotal += e.exGstSubTotal;
                            e.hsn = null;
                            e.gstRate = null;
                            e.quantity = null;
                            e.exGstRate = null;
                            e.exGstSubTotal = null;
                            e.expDate = null;
                            e.mfgDate = null;
                            e.packing = null;
                            e.uom = null;
                        }
                        // else if(e.hsn == store.data.billItems[itemKey].hsn && e.gstRate == store.data.billItems[itemKey].gstRate && e.exGstRate == store.data.billItems[itemKey].exGstRate && e.batchNo==null && e.modelNo==null && e.partNo!=null && e.partNo==store.data.billItems[itemKey].partNo && e.packing==store.data.billItems[itemKey].packing && e.uom==store.data.billItems[itemKey].uom){
                        else if(e.hsn == store.billItems[itemKey].hsn && e.gstRate == store.billItems[itemKey].gstRate && e.exGstRate == store.billItems[itemKey].exGstRate && e.batchNo==null && e.modelNo==null && e.partNo!=null && e.partNo==store.billItems[itemKey].partNo && e.packing==store.billItems[itemKey].packing && e.uom==store.billItems[itemKey].uom){
                            this.items[itemKey].quantity += e.quantity;
                            this.items[itemKey].exGstSubTotal += e.exGstSubTotal;
                            e.hsn = null;
                            e.gstRate = null;
                            e.quantity = null;
                            e.exGstRate = null;
                            e.exGstSubTotal = null;
                            e.expDate = null;
                            e.mfgDate = null;
                            e.packing = null;
                            e.uom = null;
                            e.partNo = null;
                        }
                        // else if(e.hsn == store.data.billItems[itemKey].hsn && e.gstRate == store.data.billItems[itemKey].gstRate && e.exGstRate == store.data.billItems[itemKey].exGstRate && e.batchNo==null && e.modelNo==null && e.partNo!=null && e.partNo!=store.data.billItems[itemKey].partNo && e.packing==store.data.billItems[itemKey].packing && e.uom==store.data.billItems[itemKey].uom){
                        else if(e.hsn == store.billItems[itemKey].hsn && e.gstRate == store.billItems[itemKey].gstRate && e.exGstRate == store.billItems[itemKey].exGstRate && e.batchNo==null && e.modelNo==null && e.partNo!=null && e.partNo!=store.billItems[itemKey].partNo && e.packing==store.billItems[itemKey].packing && e.uom==store.billItems[itemKey].uom){
                            this.items[itemKey].quantity += e.quantity;
                            this.items[itemKey].exGstSubTotal += e.exGstSubTotal;
                            e.hsn = null;
                            e.gstRate = null;
                            e.quantity = null;
                            e.exGstRate = null;
                            e.exGstSubTotal = null;
                            e.expDate = null;
                            e.mfgDate = null;
                            e.packing = null;
                            e.uom = null;
                        }
                        // else if(e.hsn == store.data.billItems[itemKey].hsn && e.gstRate == store.data.billItems[itemKey].gstRate && e.exGstRate == store.data.billItems[itemKey].exGstRate && e.batchNo==null && e.modelNo!=null && e.partNo!=null && e.modelNo==store.data.billItems[itemKey].modelNo && e.packing==store.data.billItems[itemKey].packing && e.uom==store.data.billItems[itemKey].uom){
                        else if(e.hsn == store.billItems[itemKey].hsn && e.gstRate == store.billItems[itemKey].gstRate && e.exGstRate == store.billItems[itemKey].exGstRate && e.batchNo==null && e.modelNo!=null && e.partNo!=null && e.modelNo==store.billItems[itemKey].modelNo && e.packing==store.billItems[itemKey].packing && e.uom==store.billItems[itemKey].uom){
                            this.items[itemKey].quantity += e.quantity;
                            this.items[itemKey].exGstSubTotal += e.exGstSubTotal;
                            e.hsn = null;
                            e.gstRate = null;
                            e.quantity = null;
                            e.exGstRate = null;
                            e.exGstSubTotal = null;
                            e.expDate = null;
                            e.mfgDate = null;
                            e.packing = null;
                            e.uom = null;
                            e.modelNo = null;
                        }
                        // else if(e.hsn == store.data.billItems[itemKey].hsn && e.gstRate == store.data.billItems[itemKey].gstRate && e.exGstRate == store.data.billItems[itemKey].exGstRate && e.batchNo==null && e.modelNo!=null && e.partNo!=null && e.modelNo!=store.data.billItems[itemKey].modelNo && e.packing==store.data.billItems[itemKey].packing && e.uom==store.data.billItems[itemKey].uom){
                        else if(e.hsn == store.billItems[itemKey].hsn && e.gstRate == store.billItems[itemKey].gstRate && e.exGstRate == store.billItems[itemKey].exGstRate && e.batchNo==null && e.modelNo!=null && e.partNo!=null && e.modelNo!=store.billItems[itemKey].modelNo && e.packing==store.billItems[itemKey].packing && e.uom==store.billItems[itemKey].uom){
                            this.items[itemKey].quantity += e.quantity;
                            this.items[itemKey].exGstSubTotal += e.exGstSubTotal;
                            e.hsn = null;
                            e.gstRate = null;
                            e.quantity = null;
                            e.exGstRate = null;
                            e.exGstSubTotal = null;
                            e.expDate = null;
                            e.mfgDate = null;
                            e.packing = null;
                            e.uom = null;
                        }
                        // else if(e.hsn == store.data.billItems[itemKey].hsn && e.gstRate == store.data.billItems[itemKey].gstRate && e.exGstRate == store.data.billItems[itemKey].exGstRate && e.batchNo==null && e.partNo==null && e.modelNo==null && e.packing==store.data.billItems[itemKey].packing && e.uom==store.data.billItems[itemKey].uom){
                        else if(e.hsn == store.billItems[itemKey].hsn && e.gstRate == store.billItems[itemKey].gstRate && e.exGstRate == store.billItems[itemKey].exGstRate && e.batchNo==null && e.partNo==null && e.modelNo==null && e.packing==store.billItems[itemKey].packing && e.uom==store.billItems[itemKey].uom){
                            this.items[itemKey].quantity += e.quantity;
                            this.items[itemKey].exGstSubTotal += e.exGstSubTotal;
                            e.hsn = null;
                            e.gstRate = null;
                            e.quantity = null;
                            e.exGstRate = null;
                            e.exGstSubTotal = null;
                            e.expDate = null;
                            e.mfgDate = null;
                            e.packing = null;
                            e.uom = null;
                            // e.partNo = null;
                        }
                        else{
                            console.log();
                        }

                        this.items.push(e);

                    }

                    // previous club hsn positiion
                }
                if(key==0){
                    e.slNo = ++this.slNo;
                    this.items.push(e); 

                    itemKey = key;

                    // this.subTotalArr = [];

                    // club together hsn, gst rate and sub total
                    this.subTotalArr.push({
                        'hsn':e.hsn,
                        'gstRate':e.gstRate,
                        'cgstAmt':e.gst/2,
                        'sgstAmt':e.gst/2,
                        'gstAmt':e.gst,
                        'taxableAmt':e.exGstSubTotal,
                    });
                }
            });

            let dumyItems = this.items;
            this.items = [];

            dumyItems.forEach(e => {
            
                if(e.name == null && e.hsn == null && e.gstRate == null && e.quantity == null && e.exGstRate == null && e.exGstSubTotal == null && e.batchNo == null && e.expDate == null && e.mfgDate == null && e.packing == null && e.uom == null && e.modelNo == null && e.partNo == null){
                    console.log();
                }
                else{
                    this.items.push(e);
                }
            });

        },
        formatCurrency(value) {
            if(value){
                return value.toLocaleString('en-IN');
            }
        },
        formatCurrencyTwoDecimal(value) {
            if(value){
                return value.toLocaleString('en-IN', {maximumFractionDigits:2, minimumFractionDigits:2});
            }
        },
        formatCurrencyFourDecimal(value) {
            if(value){
                return value.toLocaleString('en-IN', {maximumFractionDigits:4, minimumFractionDigits:4});
            }
        },
        fetchNumberToWords(n){
            return numberToWord(n)
        },
        // roundToTwo(n){
        //     return n.toFixed(2);
        // }

    },

    mounted(){
        this.getBiLLData();
    },
    // created(){

    // }

}
</script>

<style scoped>


table {
    table-layout: auto;
    min-width: 100%;
    border-collapse: collapse;
    page-break-before: always;
}

 table, th, td {
    border: 0.5px solid black;
    border-collapse: collapse;
    border-color: rgb(170, 170, 170);
  }

  .wrap-word {
    word-wrap:break-all;
    }

  .header-cell-padding{
      padding-left: 1%;
      padding-right: 1%;
  }

   .border-hidden {
    border-bottom-style: hidden;
  }

  .hide-head-border {
    border-top-style: hidden;
  }

   #additional-padding {
    grid-template-columns: 100%;
    z-index: 1;
    padding-bottom: 10%;
    min-height: 5vh;
    max-height: 5vh;
  }

  .footer-padding {
    padding: 1% 1%;
  }

.footer-margin{
    bottom: 0;
    position: sticky;
}

.footer-td{
    border-bottom-style: hidden;
    border: 0.5px solid rgb(170, 170, 170);
}

  .footer-caption {
      /* padding-top: 1%; */
      border: 0.5px solid rgb(170, 170, 170);
      border-bottom-style: hidden;
      border-right-style: hidden;
      border-left-style: hidden;
      text-align: center;
  }

  .footer-caption-two{
    border: 0.5px solid rgb(170, 170, 170);
      text-align: center;
  }

@media print {
    /* .parent {
     counter-reset: count ;
   } */

   /* .thead-page-number {
       font-size: 11px;
       counter-increment: page;
       content: "Page " counter(page);
   } */
     
     /* .thead-page-number::before {
       font-size: 11px;
       counter-increment: count 1;
       content: "Page " counter(count)
   } */

   /* @page {
    
        @bottom-left {
            content: "Page " counter(page);
        }
   } */
}
/* } */

      
</style>