<template>
  
    <div class="sm:col-12 md:col-12 lg:col-12">
        <div class="surface-card shadow-2 p-3 border-round-lg mt-2 ml-2 mr-2">
            <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl ml-2">
                    Filter Options
                </div>
                
            </div>
            <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center w-full">
                  <div class="col">
                    <label class="mt-2">Order status:</label>
                    <Dropdown class="w-full mt-2" v-model="filterAttrib1" placeholder="Select an option" :options="itemPendingStatus" optionLabel="label" optionValue="id" showClear @change="checkOrderStatus"/>
                  </div>
                  <div class="col">
                    <div v-if="!pendingToOrderStatus">
                        <label  class="mt-2">Arrival status:</label>
                        <Dropdown class="w-full mt-2" v-model="arrivalStatus" placeholder="Select an option" :options="arrivalStatusOptions" optionLabel="label" optionValue="id" showClear @change="checkArrivalStatus" />
                    </div>
                  </div>
                  <div class="col">
                    <div v-if="!notRecdStatus">
                        <label >Delivery status:</label>
                        <Dropdown class="w-full mt-2" v-model="filterAttrib2" placeholder="Select an option" :options="itemDeliveryStatus" optionLabel="label" optionValue="id" showClear />
                    </div>
                  </div>
                  <div class="col">
                    <div v-if="!notRecdStatus">
                        <label >Delivery Challan:</label>
                        <Dropdown class="w-full mt-2" v-model="deliveryChallanStatus" placeholder="Select an option" :options="deliveryChallanOptions" optionLabel="label" optionValue="id" showClear />
                    </div>
                  </div>

                  <div class="col">
                        <label >Dept PO Date:</label>
                        <Calendar v-model="poDateRange" selectionMode="range" :manualInput="true" dateFormat="dd/mm/yy" placeholder="Start date - End date" class="w-full mr-2 mt-2"></Calendar>
                    </div>
                  <!-- <div class="col">
                    <div>
                        <label >Cancel/Surrender status:</label>
                        <Dropdown class="w-full mt-2" v-model="filterAttrib3" placeholder="Select an option" :options="itemCancelStatus" optionLabel="label" optionValue="id" showClear/>
                    </div>
                  </div> -->
                  <div class="col">

                    <!-- <div class="flex">
                        <div class="flex-initial flex align-items-center justify-content-center w-full"> -->
                            <label >Category:</label>
                            <Dropdown class="w-full mt-2" v-model="selectedCategory" placeholder="Select an option" :options="categories" optionLabel="label" optionValue="key" showClear/>
                        <!-- </div>
                        
                    </div> -->

                    <!-- <div>
                    </div> -->
                  </div>
                  <div class="col">
                    <div class="flex">
                        <div class="flex-initial flex align-items-center justify-content-center w-full">
                            <Button label="Filter" raised text severity="success" class="w-full mt-4 mx-2"  @click="filterItems"/>
                        </div>
                        <div class="flex-initial flex align-items-center justify-content-center w-full">
                            <Button label="Clear Filter" raised severity="warning" class="w-full mt-4 " size="small" @click="clearFilter"/>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sm:col-12 md:col-12 lg:col-12">
        <div class="surface-card shadow-2 p-3 border-round-lg mt-2 ml-2 mr-2">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl ml-2">
                    Items Report
                </div>
                <div class="flex align-items-center justify-content-center text-xl mr-2 mb-2">
                    <!-- <Button label="Generate CS" raised size="small" class="mr-3" style="background-color:#6DBAAB; border-color:#6DBAAB" :disabled=" filterAttrib1==null ? true : !pendingToOrderStatus" /> -->
                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" class="mr-2"/>

                    <Button label="Generate CS" raised size="small" class="mr-3" style="background-color:#6DBAAB; border-color:#6DBAAB" disabled="true" />

                    <Button label="Download report" raised  size="small" style="background-color:#B46076; border-color:#B46076" @click="exportCSV" v-tooltip.top="'Excel format'"/>
                </div>
            </div>

             <div class="flex flex-wrap">
                <div class="flex align-items-center justify-content-center w-full">
                    <div class="col">
                        <DataTable ref="deptItems" :value="filteredItems" stripedRows size="small" showGridlines :loading="tableLoading" v-model:filters="filters" tableStyle="min-width: 50rem; max-width:100%" dataKey="id" paginator :rows="100" :rowsPerPageOptions="[10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" scrollable scrollHeight="400px">

                            <template #empty>
                                <div class="flex align-items-center justify-content-center my-2">
                                    <label class="text-xl font-medium">
                                        No data
                                    </label>
                                </div>
                            </template>    
                            <Column header="Sl No.">
                                <template #body="slotProps">{{1 + slotProps.index}}</template>
                            </Column>
                            <Column field="name" header="Item"></Column>
                            <Column field="category.label" header="Category"></Column>
                            <Column field="deptPo" header="Order No."></Column>
                            <Column field="deptPoDate" header="Order date"></Column>
                            <Column field="packing" header="Packing"></Column>
                            <Column field="rate" header="Rate">
                                <template #body="slotProps" >
                                    {{formatCurrency(slotProps.data.rate)}}
                                </template>
                            </Column>
                            <Column field="quantity" header="Quantity">
                                <template #body="slotProps" >
                                    {{formatNumber(slotProps.data.quantity)}}
                                </template>
                            </Column>
                            <Column field="sub_total" header="Sub-Total (Incl-GST)">
                                <template #body="slotProps" >
                                    {{formatCurrency(slotProps.data.sub_total)}}
                                </template>
                            </Column>
                            <!-- <Column header="Delivery Challan"></Column> -->
                            <Column header="Order Status">
                                <template #body="slotProps">
                                    <Tag :severity="getSeverity(slotProps.data.order_status)" :value="getValue(slotProps.data.order_status)"></Tag>
                                </template>
                            </Column>
                            <Column field="receivedData" header="Received" :exportable="false"> 
                                <template #body="slotProps">
                                    <Button v-if="slotProps.data.receivedData.length > 0" class="px-3 py-1" label="Recd" size="small" @click="toggle($event, slotProps.data.receivedData, 1)" />
                                </template>
                            </Column>
                            <Column field="deliveryStatus" header="Delivery Status" >
                                <template #body="slotProps">
                                    <Tag :severity="getDeliveryStatusSeverity(slotProps.data.deliveryStatus)" :value="getDeliveryStatusValue(slotProps.data.deliveryStatus)"></Tag>
                                </template>
                            </Column>
                            <Column v-for="(col, index) in columns" :key="index" :header="col.header" :field="col.field">
                            </Column>
                            <Column header="Challan" :exportable="false">
                                <template #body="slotProps">
                                    <label v-if="slotProps.data.deliveryChallans.length==0">
                                        No Challan
                                    </label>
                                    <label v-if="slotProps.data.deliveryChallans.length>0">
                                        <Button class="px-3 py-1" label="DC(s)" icon="pi pi-window-maximize" size="small" severity="success" @click="toggle($event, slotProps.data.deliveryChallans, 2)" />
                                    </label>
                                </template>
                            </Column> 
                        </DataTable>

                        <OverlayPanel ref="opPrimary">
                            <DataTable :value="opData" >
                                <Column v-for=" (col, index) in opTableColumns" :key="index" :field="col.field" :header="col.header">
                                </Column>
                                <!-- <Column field="date" header="Date"></Column> -->
                                <template #footer> Total quantity {{ formatNumber(getTotalQty()) }} </template>
                            </DataTable>
                        </OverlayPanel>
                    </div>
                </div>
             </div>
        </div>
    </div>

</template>

<script>
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { deptOrderItemsList, getCategories } from '@/services/apiService'
import Tag from 'primevue/tag'
import OverlayPanel from 'primevue/overlaypanel'
import Calendar from 'primevue/calendar'
import { FilterMatchMode } from 'primevue/api';
// import { h } from 'vue'

export default {
    components:{
        InputText,
        Button,
        Dropdown,
        DataTable,
        Column,
        Tag,
        OverlayPanel,
        Calendar,
    },
    data(){
        return{
            categories:[],
            columns:[],
            selectedCategory:null,
            filterAttrib1:null,
            itemPendingStatus:[
                {label:'Pending to order', id:0},
                {label:'Ordered', id:1},
            ],
            arrivalStatus:null,
            arrivalStatusOptions:[
                {label:'Not Received', id:0},
                {label:'Received', id:1},
            ],
            filterAttrib2:null,
            itemDeliveryStatus:[
                {label:'Pending delivery', id:0},
                {label:'Fully delivered', id:1},
                {label:'Partially delivered', id:2},
            ],
            filterAttrib3:null,
            itemCancelStatus:[
                {label:'Cancelled', id:1},
                {label:'Surrendered', id:2},
            ],
            deliveryChallanStatus:null,
            deliveryChallanOptions:[
                {label:'Generated', id:1},
                {label:'Not Generated', id:0},
            ],
            deptItemsArr:[],
            filteredItems:[],
            tableLoading:false,
            pendingToOrderStatus:true,
            notRecdStatus:true,
            opData:[],
            opTableColumns:[],
            poDateRange:[],
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                item_name: { value: null, matchMode: FilterMatchMode.IN },
            },
        }
    },
    methods:{
        fetchAllItems(){
            this.tableLoading = true;
            deptOrderItemsList().then((res)=>{            
                this.deptItemsArr = res.data;
                this.filteredItems = res.data;
                this.tableLoading = false;
                this.calculateTotalValue(this.deptItemsArr);
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.tableLoading = false;
            });
        },
        checkOrderStatus(){
            if(this.filterAttrib1 == 1){
                this.pendingToOrderStatus = false;
                this.columns = [
                    {header:'OEM PO', field:'oem_po'},
                    // {header:'Received', field:'received'},
                ];
            }
            if(this.filterAttrib1 == 0 || this.filterAttrib1 == null){
                this.pendingToOrderStatus = true;
                this.notRecdStatus = true;
                this.arrivalStatus = null;
            }
        },
        checkArrivalStatus(){
            if(this.arrivalStatus == 1){
                this.notRecdStatus = false;
            }
            if(this.arrivalStatus == 0 || this.arrivalStatus == null){
                this.notRecdStatus = true;
                this.filterAttrib2 = null;
            }
        },
        getSeverity(data){
           switch (data) {
                case 0:
                    return 'danger'
                case 1:
                    return 'success';
                case -1:
                    return 'warning';
                default:
                    break;
            }
        },
        getValue(data){
            switch (data) {
                case 0:
                    return 'Not Ordered'
                case 1:
                    return 'Ordered';
                case -1:
                    return 'Partially Ordered';
                default:
                    break;
            }
        },
        getDeliveryStatusSeverity(status){
            switch (status) {
                case 0:
                    return 'danger'
                case 1:
                    return 'success';
                case 2:
                    return 'warning';
                default:
                    break;
            }
        },
        getDeliveryStatusValue(status){
            switch (status) {
                case 0:
                    return 'Pending delivery'
                case 1:
                    return 'Delivered';
                case 2:
                    return 'Partially delivered';
                default:
                    break;
            }
        },
        calculateTotalValue(items){
            let sum = 0;
            items.forEach(e => {
                sum += e.rate * e.quantity;
            });         
            this.$emit('total-value', sum, 0, 0, 0);
        },
        filterItems(){

            var startDate = null;
            var endDate = null;

            if(this.poDateRange[0]!=null && this.poDateRange[1]!=null){
                startDate = this.poDateRange[0];
                endDate = this.poDateRange[1];
            }

            this.tableLoading = true;
            let tmp = [];
            this.deptItemsArr.forEach(e => {

                var deptDate2 = e.deptPoDate.split("-");
                
                var deptPoDate = new Date(deptDate2[2], (deptDate2[1]-1), deptDate2[0]);                

                if(  ( this.filterAttrib1!=null ? ((this.filterAttrib1==0 && e.order_status==0) || (this.filterAttrib1==1 && e.order_status!=0)) : true) && ( this.arrivalStatus!=null ? e.received==this.arrivalStatus : true ) && (this.selectedCategory!=null ? this.selectedCategory==e.category.key : true) && (this.filterAttrib2!=null ? this.filterAttrib2==e.deliveryStatus : true) && (this.deliveryChallanStatus!=null ? (this.deliveryChallanStatus==0 && e.deliveryChallans.length==0) || (this.deliveryChallanStatus==1 && e.deliveryChallans.length>0) : true) && ((startDate!=null && endDate!=null) ? (deptPoDate >= startDate && deptPoDate <= endDate) : true)){                   
                    tmp.push(e);
                }
            });

            this.filteredItems = [];
            this.filteredItems = tmp;
            this.calculateTotalValue(this.filteredItems);
            this.tableLoading = false;
            
            
        },
        clearFilter(){
            this.tableLoading = true;

            this.filterAttrib1 = null;
            this.arrivalStatus = null;
            this.filterAttrib2 = null;
            this.deliveryChallanStatus = null;
            this.pendingToOrderStatus = true;
            this.notRecdStatus = true;
            this.selectedCategory = null;
            this.poDateRange = [];
            this.filteredItems = this.deptItemsArr;
            this.calculateTotalValue(this.filteredItems);
            this.tableLoading = false;
        },
        toggle(event, data, option) {
            this.opData = data;
            if(option==1){
                this.opTableColumns = [
                    {header:'Quantity', field:'quantity'},
                    {header:'Date', field:'date'},
                ];
            }
            if(option==2){
                this.opTableColumns = [
                    {header:'Challan', field:'challanNo'},
                    {header:'Quantity', field:'quantity'},
                ];
            }
            this.$refs.opPrimary.toggle(event);

        },
        getTotalQty(){
            return this.opData.reduce((accumulator, current) => accumulator + current.quantity, 0);
        },
        exportCSV() {
            this.$refs.deptItems.exportCSV();
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 4000 });
        },
        formatNumber(value) {
            return value.toLocaleString('en-IN');
        },
        formatCurrency(value) {
            return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR', maximumFractionDigits:2 });
        },
    },
    mounted(){
        getCategories().then((res)=>{
            this.categories = res.data;            
        }).catch((error)=>{
            this.showErrorToast(error.response.data)
        });
    this.fetchAllItems();

    }
}
</script>

<style>

</style>