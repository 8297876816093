<template>

<!-- <Navbar/>
  <SideBar/> -->

  <div class="card main-content">

    <!-- <div class="surface-ground "> -->
        <Card style="width:70%">
                <template #content>
                    <div class="flex justify-content-start">
                        <Button label="Print" text raised size="small" @click="printPdf()" class="mr-2"/>
                        <!-- <Button label="Back" text raised severity="contrast" /> -->
                        <router-link to="/oem-po-list"  rel="noopener">
                            <!-- <Button label="Back" severity="contrast" text raised size="small"/> -->
                            <Button label="Back" severity="contrast" text raised size="small" @click="$router.back()"/>

                        </router-link>
                    </div>
                    <hr>

                    <table class="white-bg " id="oem_pdf_div">
                        <OemPoHeader/>
                        <tbody >
                            <div class="content" style="font-family:'Times New Roman'; font-size:16px;">
                                <tr>
                                    <td ><label for="">Order No.: {{orderNo}}</label></td>
                                    <td ><label for="">Date: {{orderDate}}</label></td>
                                </tr>
                                <tr ><td style="padding-top: 10px;" colspan="2"><label for="">To,</label></td></tr>
                                <tr class="minimal-line-height">
                                    <td colspan="2">
                                        <label for="">
                                            {{manufacturerName}}
                                        </label>
                                    </td>
                                </tr>
                                <tr class="minimal-line-height">
                                    <td colspan="2">
                                        <label for="">
                                            {{manufacturerAdd_1}}
                                        </label>
                                    </td>
                                </tr>
                                <tr class="minimal-line-height">
                                    <td colspan="2">
                                        <label for="">
                                            {{manufacturerAdd_2}}
                                        </label>
                                    </td>
                                </tr>
                                <tr v-if="emailStatus" class="minimal-line-height">
                                    <td colspan="2">
                                        <label for="">
                                            Email: {{email}}
                                        </label>
                                    </td>
                                </tr>
                                <tr v-if="contactPersonStatus" class="py-2 minimal-line-height">
                                    <td style="padding-top: 10px;" colspan="2">
                                        <label for="">
                                            Kind Attn:{{contactPerson}} ({{contactPersonPhone}})
                                        </label>
                                    </td>
                                </tr>
                                <tr class="minimal-line-height">
                                    <td style="padding-top: 10px;" colspan="2">
                                        <label for="">
                                            Sub: Purchase Order
                                        </label>
                                    </td>
                                </tr >
                                <tr class="minimal-line-height">
                                    <td style="padding-top: 10px;" colspan="2">
                                        <label class="mb-3">
                                        Sir,<br>
                                        </label>
                                        <p class="minimal-line-height">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;With reference to Subject mentioned above we would like to place order for the Items mentioned <label v-if="!itemAnnexure">below</label> <label v-if="itemAnnexure"><strong> in Annexure I</strong></label>. Please <strong>Read the Terms & Conditions thoroughly.</strong>
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="2">
                                        <table class="border-design" style="width:100%">
                                                        <label v-if="!itemAnnexure" for="">
                                                            <tr class="table-header-grey border-design">
                                                                <th style="width:5%">Sl No.</th>
                                                                <th style="vertical-align: middle;" class="px-1">Name of Item</th>
                                                                <th v-if="addlColStatus" style="vertical-align: middle;">{{addlInfoCol}}</th>
                                                                <th style="vertical-align: middle;" class="px-1">Packing</th>
                                                                <th style="vertical-align: middle;" class="px-2">Quantity</th>
                                                                <th style="vertical-align: middle;" class="px-2">Rate <br> Ex-GST</th>
                                                                <th style="vertical-align: middle;" class="px-1">Gst (%)</th>
                                                            </tr>                                                        
                                                            <OemItems v-for="(item, index) in items" :key="item" :index="index" :no="index+1" :item="item" :addlColStatus="addlColStatus" />
                                                        </label>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="2">
                                        <div class="py-3"><strong>Terms and Conditions:-</strong></div>
                                        <table style="width:100;">
                                            <TermsAndConditiions v-for="(item, index) in termsConditionsArr" :key="item.id" :index="index" :item="item" />
                                        </table>
                                        <div>
                                            <span>
                                            <td>
                                                <strong>{{lastSlNo}})&nbsp;&nbsp;&nbsp;&nbsp;Delivery Address:&nbsp;&nbsp; <label v-if="annexureStatus"><strong>As per Annexure {{deliveryAnnexNo}}</strong></label></strong> 
                                                <label v-if="singleLocation">
                                                    <strong>{{locationName[0]}}</strong>
                                                </label>
                                            </td>
                                            <div v-if="!singleLocation">
                                                <label v-if="!annexureStatus">
                                                    <table class="mx-7 my-1 border-design" style="width:90%">
                                                            <tr class="table-header-grey">
                                                                <th style="width:5%">Sl No.</th>
                                                                <th>Name of Item</th>
                                                                <!-- <th>District</th> -->
                                                                <th>Location</th>
                                                                <th>Quantity</th>
                                                            </tr>
                                                            <NonAnnex v-for="(location, index) in locationArr" :key="location" :slNo="index+1" :deliveryLocation="location"/>
                                                    </table>
                                                </label>  
                                                <!-- <label v-if="annexureStatus"><strong>As per Annexure {{deliveryAnnexNo}}</strong></label> -->
                                            </div>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="penaltyClauseStatus">
                                    <td colspan="2" >
                                        <div class="mt-3"><strong>Penalty Clause:</strong></div>
                                        <div class="flex align-items-center justify-content-center mb-3">
                                            <PenaltyClause v-for="(clause, index) in penaltyClauseArr" :key="index" :item="clause"/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div class="flex align-items-center justify-content-center">
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Kindly acknowledge and share the Performa invoice for advance payments within 24 hours of receipt of this Order.
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td >
                                        <div class="py-3 px-5">
                                            <Image src="/assets/images/transparent_ams_sign.png" alt="AMS" width="170"/>
                                        </div>
                                    </td>
                                </tr>

                                <!-- more no. of items are placed in annexure -->
                                <tr v-if="itemAnnexure">
                                    <td colspan="2">
                                        <div class="page-breaker">
                                            <table class="border-design" style="width:100%">
                                                <tr>
                                                    <th colspan="6">Annexure {{itemAnnexNo}}</th>
                                                </tr>
                                                <tr class="table-header-grey">
                                                    <th style="width:5%">Sl No.</th>
                                                    <th class="px-1">Name of Item</th>
                                                    <th class="px-1" v-if="addlColStatus" style="vertical-align: middle;">{{addlInfoCol}}</th>
                                                    <th class="px-2">Packing</th>
                                                    <th class="px-2">Quantity</th>
                                                    <th class="px-2">Rate <br> Ex-GST</th>
                                                    <th class="px-1">Gst (%)</th>
                                                </tr>
                                                <OemItems v-for="(item, index) in items" :key="item" :index="index" :no="index+1" :item="item" :addlColStatus="addlColStatus"/>
                                            </table>
                                        </div>
                                        
                                    </td>
                                </tr>

                                <!--  -->
                                <tr v-if="annexureStatus" class="page-breaker">
                                    <td colspan="2">
                                        <div class="page-breaker pt-2">
                                            <table class="border-design" style="width:100%">
                                                <tr>
                                                    <th colspan="4">Annexure {{deliveryAnnexNo}}</th>
                                                </tr>
                                                <tr class="table-header-grey">
                                                    <th>Name of Item</th>
                                                    <!-- <th>District</th> -->
                                                    <th>Location</th>
                                                    <th class="minorCol">Quantity</th>
                                                </tr>
                                                <Annexure v-for="(location, index) in locationArr" :key="location" :index="index" :deliveryLocation="location"/>
                                            </table>
                                        </div>
                                        
                                    </td>
                                </tr>
                                
                            </div>
                        </tbody>
                        <OemPoFooter/>
                    </table>

                </template>
        </Card>
    <!-- </div> -->
  </div>


</template>

<script>
import Image from 'primevue/image';
import { useOemPoStore } from '../../stores/oemPo'
import TermsAndConditiions from './termsConditions.vue'
import Annexure from './annexureList.vue'
import NonAnnex from './nonAnnexureList.vue'
import OemItems from './oemItems.vue'
import OemPoHeader from './poPdfHeader.vue'
import OemPoFooter from './poPdfFooter.vue'
import PenaltyClause from './penaltyClause.vue';
// import Navbar from '../header/headerNavbar.vue'
// import SideBar from '../sidebar/sideBarTwo.vue'
import Card from 'primevue/card';
import Button from 'primevue/button';

 export default{
     components:{ 
        //  Navbar,
        // SideBar,
        TermsAndConditiions, 
        Annexure, 
        NonAnnex, 
        OemItems,
        OemPoHeader, 
        OemPoFooter, 
        Image, 
        PenaltyClause,
        Card,
        Button,
    },
    data(){
        return {
            count:0,
            orderNo:null,
            orderDate:null,
            manufacturerName:null,
            manufacturerAdd_1:null,
            manufacturerAdd_2:null,
            contactPerson:null,
            contactPersonPhone:null,
            items:[],
            termsConditions:null,
            termsArr:[],
            termsConditionsArr:[],
            termsNo:0,
            lastSlNo:null,
            annexureStatus:false,
            itemAnnexure:false,
            deliveryAnnexNo:'I',
            itemAnnexNo:'I',
            deliveryLocations:[],
            locationArr:null,
            innerLevel:0,
            outerLevel:0,
            innerMax:1,
            locationCount:0,
            locationName:[],
            singleLocation:false,
            email:null,
            addlColStatus:false,
            addlInfoCol:null,
            contactPersonStatus:false,
            emailStatus:false,
            penaltyClauseStatus:false,
            penaltyClause:[],
            penaltyClauseArr:[],
            penaltyNo:0,
            storeDelivery:null,
        }
    },
    methods:{
        getOemPoData(){

            const store = useOemPoStore();    
            
            // location.reload();

            if(store.data.oem_po_details[0].add_on_head){
                this.addlColStatus = true;
                this.addlInfoCol = store.data.oem_po_details[0].add_on_head;
            }

            this.orderNo = store.data.po_no;
            this.orderDate = store.data.po_date;
            this.manufacturerName = store.data.manufacturer.name;
            
            const oemLocality = store.data.manufacturer.locality!=null ?store.data.manufacturer.locality:'';
            const oemHNo = store.data.manufacturer.house_no!=null ? store.data.manufacturer.house_no : '';
            if(oemLocality && oemHNo){
                this.manufacturerAdd_1 = oemHNo+', '+oemLocality;
            }
            if(oemLocality && !oemHNo){
                this.manufacturerAdd_1 = oemLocality+',';
            }
            if(!oemLocality && oemHNo){
                this.manufacturerAdd_1 = oemHNo+',';
            }
            this.manufacturerAdd_2 = store.data.manufacturer.city+', '+store.data.manufacturer.state+'-'+store.data.manufacturer.pincode;
            this.contactPerson = store.data.manufacturer.contact_person;
            this.contactPersonPhone = store.data.manufacturer.contact_phone;
            
            this.storeDelivery = store.data.store_delivery;

            if(this.contactPerson){
                this.contactPersonStatus = true;
            }

            this.email = store.data.manufacturer.email;
            if(this.email){
                this.emailStatus = true;
            }

            // merging same item start

            let e = store.data;

            for (let i = 0; i < e.oem_po_details.length-1; i++) {
                        for (let j = i+1; j < e.oem_po_details.length; j++) {
                            if(e.oem_po_details[i].roc_item_id!=null){
                                if(e.oem_po_details[i].roc_item_id==e.oem_po_details[j].roc_item_id && e.oem_po_details[i].item_name==e.oem_po_details[j].item_name && e.oem_po_details[i].rate==e.oem_po_details[j].rate){
                                    e.oem_po_details[i].quantity += e.oem_po_details[j].quantity;
                                    e.oem_po_details[i].nop += e.oem_po_details[j].nop;
                                    e.oem_po_details[i].sub_total += e.oem_po_details[j].sub_total;
                                    e.oem_po_details[i].location = e.oem_po_details[i].location.concat(e.oem_po_details[j].location);

                                    // clubbing qty for same delivery location
                                    for (let k = 0; k < e.oem_po_details[i].location.length-1; k++) {
                                        for (let l = k+1; l < e.oem_po_details[i].location.length; l++) {
                                            if(e.oem_po_details[i].location[k].id==e.oem_po_details[i].location[l].id){
                                                e.oem_po_details[i].location[k].quantity += e.oem_po_details[i].location[l].quantity;
                                                e.oem_po_details[i].location.splice(l, 1);
                                            }
                                        }    
                                    }
                                    e.oem_po_details.splice(j, 1);
                                }
                            }
                            if(e.oem_po_details[i].non_roc_item_id!=null){
                                if(e.oem_po_details[i].non_roc_item_id==e.oem_po_details[j].non_roc_item_id && e.oem_po_details[i].item_name==e.oem_po_details[j].item_name && e.oem_po_details[i].rate==e.oem_po_details[j].rate){
                                    e.oem_po_details[i].quantity += e.oem_po_details[j].quantity;
                                    e.oem_po_details[i].nop += e.oem_po_details[j].nop;
                                    e.oem_po_details[i].sub_total += e.oem_po_details[j].sub_total;
                                    e.oem_po_details[i].location = e.oem_po_details[i].location.concat(e.oem_po_details[j].location);

                                    // clubbing qty for same delivery location
                                    for (let k = 0; k < e.oem_po_details[i].location.length-1; k++) {
                                        for (let l = k+1; l < e.oem_po_details[i].location.length; l++) {
                                            if(e.oem_po_details[i].location[k].id==e.oem_po_details[i].location[l].id){
                                                e.oem_po_details[i].location[k].quantity += e.oem_po_details[i].location[l].quantity;
                                                e.oem_po_details[i].location.splice(l, 1);
                                            }
                                        }    
                                    }
                                    e.oem_po_details.splice(j, 1);
                                }
                            }
                        }                        
                    }

            // merging same item end
            this.items = e.oem_po_details;

            if(this.items.length>5){
                this.itemAnnexure = true;
                this.deliveryAnnexNo = 'II';
            }
            this.termsConditions = store.data.terms_and_contions;
            this.getTermsConditions(this.termsConditions);
            
            if(store.data.penalty_clause){
                this.penaltyClauseStatus = true;
                // this.penaltyClause = store.data.penalty_clause;
                this.getPenaltyClause(store.data.penalty_clause);
            }

            this.getDeliveryLocations(this.items);
            if(this.storeDelivery){
                this.annexureStatus = false;
                this.singleLocation = true;
                this.locationName[0] =  this.storeDelivery.location+', '+this.storeDelivery.address;               
            }
        },
        getTermsConditions(data){
            this.termsArr = data.split(/\r?\n/);
            this.termsArr.forEach(element => {
                
                this.termsConditionsArr.push({
                    slNo:++this.termsNo,
                    content:element,
                });
            });
            this.lastSlNo = this.termsNo + 1;
        },
        getPenaltyClause(data){
            this.penaltyClause = data.split(/\r?\n/);
            this.penaltyClause.forEach(e => {
                
                this.penaltyClauseArr.push({
                    slNo:++this.penaltyNo,
                    content:e,
                });
            });
        },
        getDeliveryLocations(data){
            
            data.forEach((element, key) => {

                this.deliveryLocations[key] = {
                    'item_name':element.item_name,
                    'quantity':element.quantity,
                    'location':element.location,
                }
            });

            this.deliveryLocations.forEach((element) => {
                this.outerLevel++;

                element.location.forEach(() => {
                        this.innerLevel++;
                        this.locationCount++;
                });
            
                if(this.innerLevel > this.innerMax)
                    this.innerMax = this.innerLevel;
                this.innerLevel = 0;
            });

            if(this.locationCount==this.deliveryLocations.length){
                this.deliveryLocations.forEach((element, key) => {                    
                    this.locationName[key] = element.location[0].location;
                });

                let flag = 0;
                for (let index = 1; index < this.locationName.length; index++) {
                    if(this.locationName[index]!=this.locationName[index-1]){ 
                            flag = 1;
                            break;
                        }                
                }
                if(flag==0) return this.singleLocation = true;
            }

                if(this.innerMax>1 || (this.innerMax=1 && this.outerLevel>3)){
                    this.annexureStatus=true;
                }
                this.locationArr = this.deliveryLocations;
        },
        printPdf(){
            window.print();
        }
    },
    mounted(){      
        
        this.getOemPoData();   
    },
    unmounted(){        
        const store = useOemPoStore();  
        store.$reset();
    }
 }
</script>

<style scoped>
.white-bg{
    background-color: white;
}

.border-design{
    border: 0.5px #000000;
    border-collapse: collapse;
}

.content {
    position:relative;
    margin-bottom:20%;
    width: 100%;
    height:auto;
    font-size: 0.7em;
}

.table-header-grey{
    background-color: grey;
}

.footer{
    position: fixed;
    bottom: 0;
    height:20px;
    padding-top: 10%;
}

.page-breaker{
    page-break-before: always;
}

th{
    border: 0.5px solid #000000;
    border-collapse: collapse;
}

tr { page-break-inside: avoid }

.minimal-line-height{
    line-height: 0.9em;
}

@media print {
  body {
    visibility: hidden;
  }
  #oem_pdf_div {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
}

</style>